import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import UseFormContext from "../../../context/UseFormContext";
import { getQuestionaire } from "../../../API/authCurd";
import { useFormikContext } from "formik";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

function MedicalQuestionnaire(props) {
  const formContext = UseFormContext();
  const [questions, setQuestions] = useState([]);
  const [groupedQuestions, setGroupedQuestions] = useState([])
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [questionAnswerArray, setQuestionAnswerArray] = useState([]);

  const [memberSelected, setMemberSelected] = useState(false);
  const { formik, carrierId } = props;
  useEffect(() => {
    fetchQuestions();
  }, []);


  function mapDiseasesToMembers(questions, members) {
    // if (!formContext?.questionSet) {
    const multipleMemberData = members?.map((member) => {
      console.log('Mapping Diseases to Member:', member);
      const memberSelectedDiseaseQuestions = questions?.map((q) => ({
        question: q.question,
        questionSetCd: q.questionSetCd,
        questionCd: q.questionCd,
        response: "NO",
      }));

      return {
        memberRelation: member.relation,
        memberName: member.name,
        memberSelectedDiseaseQuestions,
      };
    });
    return multipleMemberData;
    // }
  }

  const fetchQuestions = async () => {
    try {
      setQuestions([]); // stores the common question 
      setGroupedQuestions([]); //stores sub group question 

      const mockQuestions = await getQuestionaire(67);
      console.log("mockquestions", mockQuestions);  // fetched all questionnair from qustionaire 
      const parsedResponse = JSON.parse(mockQuestions);
      console.log("parsedResponse", parsedResponse)  // parsing the data of mockQuestions
      const data = parsedResponse.data;
      console.log('Fetched Questions:', data);
      let allQuestions = [];
      let allGroupedQuestions = [];
      data.forEach((dataItem) => {
        dataItem.commonQuestion.forEach((questionItem) => {
          allQuestions.push({
            ...questionItem,
            selectedOption: null,
          });
          if (questionItem?.options?.length > 0) {
            allGroupedQuestions.push(...questionItem.options.map((option) => option?.nextQuestions));
          }
        });
      });

      setQuestions(allQuestions);
      setGroupedQuestions(allGroupedQuestions.flat());

      // if (!formContext.questionSet) {
      const result = mapDiseasesToMembers(
        parsedResponse.data,
        props.formik.values.familyDetails
      );
      console.log('result', result);
      result.forEach(member => {
        member.memberSelectedDiseaseQuestions.forEach(question => {
          question.memberRelation = `${member.memberRelation}_${member.memberName}`;
        });
      });
      props.formik.setFieldValue("InsuredMemberDetails", result);
      formContext.setQuestionSet(true);
      // }
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };
  //new
  const handleCheckboxChange = (member, questionId, optionValue) => {
    console.log(`Handling checkbox change for questionId: ${questionId}, Option: ${optionValue}`);
    const updatedQuestions = questions.map((question) =>
      question.id === questionId ? { ...question, selectedOption: optionValue } : question
    );
    console.log('member', member);
    console.log("`questions[${member.memberRelation}_${member.memberName}_${questionId}].selectedOption`", `questions[${member.memberRelation}_${member.memberName}_${questionId}].selectedOption`)
    setQuestions(updatedQuestions);
    props.formik.setFieldValue(`questions[${member.memberRelation}_${member.memberName}_${questionId}].selectedOption`, optionValue);
    let updatedOption = optionValue === "NO" ? "NO" : "YES";
    console.log('updatedOption', updatedOption);
    const nextQuestions = updatedQuestions
      .find((q) => q.id === questionId)
      ?.options
      ?.find((option) => option.value === updatedOption)
      ?.nextQuestions;
    console.log('Next questions for this option:', nextQuestions);

    setAnsweredQuestions((prev) => ({
      ...prev,
      [questionId]: optionValue,
    }));
  };

  const getFlattenedQuestions = (optionsArray) => {
    let flattened = [];
    optionsArray.forEach(item => {
      if (item.nextQuestions && item.nextQuestions.length) {
        flattened = flattened.concat(getFlattenedQuestions(item.nextQuestions));
      }

      if (item.options) {
        item.options.forEach(option => {
          if (option.nextQuestions) {
            flattened = flattened.concat(getFlattenedQuestions(option.nextQuestions));
          }
        });
      }

      if (item.id) {
        flattened.push({
          id: item.id,
          questionSetCd: item.questionSetCd,
          question: item.question,
          type: item.type,
          questionCd: item.questionCd
        });
      }
    });

    return flattened;
  };

  useEffect(() => {
    props.formik.values.familyDetails?.map((member) => {
      console.log('Mapping Diseases to Member:', questionAnswerArray);
      const relationData = questionAnswerArray[member.relation];
      console.log("relationData", relationData, member)
      member.memberSelectedDiseaseQuestions = relationData?.length > 0 ? relationData : [];
    });
  }, [questionAnswerArray])
  const [activeAccordion, setActiveAccordion] = useState(null);
  const toggleAccordion = (member, index) => {
    setMemberSelected(member)
    setActiveAccordion(activeAccordion === index ? null : index);
  };


  console.log("formik values", props?.formik?.values)
  // console.log(" AnswerArray---->", answeredQuestions)
  // console.log(" questionAnswerArray---->", questionAnswerArray)
  console.log("questionAnswerArray", questionAnswerArray)

  useEffect(() => {
    const storedData = localStorage.getItem('memberSelectedDiseaseQuestions');
    if (storedData) {
      setQuestionAnswerArray(JSON.parse(storedData));
      console.log("questionAnswerArray",questionAnswerArray)
    }
  }, []);
  
  useEffect(() => {
    localStorage.setItem('memberSelectedDiseaseQuestions', JSON.stringify(questionAnswerArray));
  }, [questionAnswerArray]);

  return (
    <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
      <div className="form-title pt-0 d-block mb-3">
        <h6 className="form-sub-title">Help us know the medical condition, if any</h6>
        <p className="text-grey mb-0">We'll only ask for the details insurance companies need</p>
      </div>

      {/* Loop through InsuredMemberDetails */}
      {props?.formik?.values?.InsuredMemberDetails?.map((member, memberIndex) => (
        <Card style={{ margin: "1%", paddingLeft: "15px" }} key={memberIndex} className="mb-3">
          <Row>
            <Col sm={12} className="pt-4">
              <label
                className="mb-1 fw-600"
                onClick={() => toggleAccordion(member, memberIndex)}
                style={{ cursor: "pointer" }}
              >
                {member.memberRelation} - {member.memberName}
                <span style={{ marginLeft: "10px" }}>
                  {/* Show up arrow if activeAccordion matches, else show down arrow */}
                  {activeAccordion === memberIndex ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              </label>
              <p className="text-grey mb-2">{`Medical Questions for ${member.memberName}`}</p>
              {activeAccordion === memberIndex && (
                questions.map((question) => {
                  return (

                    <Card style={{ margin: "1%", paddingLeft: "15px" }} key={question.id} className="mb-3">
                      <Row>
                        <Col sm={12} className="pt-4">
                          <label className="mb-1 fw-600">{question.question}</label>
                          {/* <p className="text-grey mb-0">Choose YES/NO below:</p> */}
                        </Col>

                        <Row className="custom-checkbox" style={{ margin: "10px 10px 10px 0px" }}>
                          <Col lg={4} md={6} sm={6}>

                            <div className="checkbox-wrap">
                              <input
                                type="checkbox"
                                name={`question-${question.id}`}
                                checked={props?.formik?.values?.questions?.[`${member.memberRelation}_${member.memberName}_${question.id}`]?.selectedOption === "YES"}
                                onChange={(e) => {
                                  console.log(`Selected YES for question: ${question.id}`);

                                  const isChecked = e.target.checked;
                                  const newOption = isChecked ? "YES" : "";
                                  const flattenedSubQuestions = getFlattenedQuestions(question.options ? question.options : []);
                                  console.log('Flattened sub questions', { flattenedSubQuestions });

                                  flattenedSubQuestions.forEach((subquestion) => {
                                    if (subquestion.type === 'checkbox') {
                                      props.formik.setFieldValue(`${`${member.memberRelation}_${member.memberName}_${subquestion.id}`}`, "NO");
                                    } else {
                                      props.formik.setFieldValue(`${`${member.memberRelation}_${member.memberName}_${subquestion.id}`}`, "");
                                    }
                                  });
                                  setQuestionAnswerArray((prevArray) => {
                                    // Clone the previous state to avoid mutation
                                    const updatedArray = { ...prevArray };
                                    // Get the member's responses (initialize if not already present)
                                    const memberResponses = updatedArray[member.memberRelation] || [];

                                    // Filter out the existing response for the current question
                                    const filteredResponses = memberResponses.filter(
                                      (item) => item.questionCd !== question.questionCd
                                    );

                                    // Add the new response if the checkbox is checked
                                    if (isChecked) {
                                      // Reset sub-question answers when "NO" is selected
                                      // question.options.forEach(option => {
                                      //   option.nextQuestions.forEach(subQuestion => {
                                      //     // Reset the sub-question value for this member
                                      //     const filteredSubResponses = memberResponses.filter(
                                      //       (item) => item.questionCd !== subQuestion.questionCd
                                      //     );
                                      //     updatedArray[member.memberRelation] = filteredSubResponses;
                                      //     props.formik.setFieldValue(`${`${member.memberRelation}_${subQuestion.id}`}`, "NO");
                                      //   });
                                      // });



                                    }

                                    updatedArray[member.memberRelation] = [
                                      ...filteredResponses,
                                      {
                                        questionSetCd: question.questionSetCd,
                                        questionCd: question.questionCd,
                                        response: newOption
                                      }
                                    ];

                                    return updatedArray;
                                  });

                                  handleCheckboxChange(member, question.id, newOption);
                                }}
                              />
                              <label className="label-text">YES</label>
                            </div>
                          </Col>
                          <Col lg={4} md={6} sm={6}>

                            <div className="checkbox-wrap">
                              <input
                                type="checkbox"
                                name={`question-${question.id}`}
                                checked={props?.formik?.values?.questions?.[`${member.memberRelation}_${member.memberName}_${question.id}`]?.selectedOption === "NO"}
                                onChange={(e) => {
                                  console.log(`Selected NO for question: ${question.id}`);

                                  const isChecked = e.target.checked;
                                  const newOption = isChecked ? "NO" : "";
                                  const flattenedSubQuestions = getFlattenedQuestions(question.options ? question.options : []);

                                  setQuestionAnswerArray((prevArray) => {
                                    // Clone the previous state to avoid mutation
                                    let updatedArray = { ...prevArray };
                                    // Get the member's responses (initialize if not already present)
                                    let memberResponses = updatedArray[member.memberRelation] || [];

                                    // Filter out the existing response for the current question and sub-questions
                                    let filteredResponses = memberResponses.filter(
                                      (item) => item.questionCd !== question.questionCd
                                    );
                                    filteredResponses = filteredResponses.filter(
                                      (selectedQuestion) => !flattenedSubQuestions.some(
                                        (flattenedQuestion) => flattenedQuestion.questionCd === selectedQuestion.questionCd
                                      )
                                    );

                                    // Add the new response if the checkbox is checked
                                    if (isChecked) {
                                      filteredResponses.push({
                                        questionSetCd: question.questionSetCd,
                                        questionCd: question.questionCd,
                                        response: "NO"
                                      });
                                      memberResponses = filteredResponses;
                                    }

                                    // Update the array with the filtered responses
                                    updatedArray[member.memberRelation] = memberResponses;

                                    return updatedArray;
                                  });

                                  handleCheckboxChange(member, question.id, newOption);
                                }}
                              />
                              <label className="label-text">NO</label>
                            </div>
                          </Col>
                        </Row>


                        {/*old---- Display grouped questions (sub-questions) if YES is selected */}
                        {question.selectedOption === "YES" && Array.isArray(question.options) && question.options.length > 0 && (
                          <div className="next-questions">
                            {question.options.map((option, optionIndex) => (
                              <div key={optionIndex}>
                                {Array.isArray(option.nextQuestions) && option.nextQuestions.length > 0 &&
                                  option.nextQuestions.map((subQuestion, subIndex) => (
                                    <div key={subIndex} className="sub-question mt-2 mb-2">
                                      <div className="checkbox-wrap">
                                        <input
                                          type="checkbox"
                                          checked={props.formik.values[`${`${member.memberRelation}_${member.memberName}_${subQuestion.id}`}`] === "YES"}
                                          onChange={(e) => {
                                            console.log(`Sub-question checkbox changed: ${subQuestion.id}, Checked: ${e.target.checked}`);
                                            const isChecked = e.target.checked;
                                            const flattenedSubQuestions = getFlattenedQuestions(subQuestion.options ? subQuestion.options : []);
                                            setQuestionAnswerArray((prevArray) => {
                                              const updatedArray = { ...prevArray };
                                              let memberResponses = updatedArray[member.memberRelation] || [];
                                              let filteredResponses = memberResponses.filter(
                                                (item) => item.questionCd !== subQuestion.quesionCd
                                              );
                                              filteredResponses = filteredResponses.filter(
                                                (selectedQuestion) => !flattenedSubQuestions.some(
                                                  (flattenedQuestion) => flattenedQuestion.questionCd === selectedQuestion.questionCd
                                                )
                                              );
                                              if (isChecked) {
                                                filteredResponses.push({
                                                  questionSetCd: subQuestion.questionSetCd,
                                                  questionCd: subQuestion.questionCd,
                                                  response: question.selectedOption === "YES" ? e.target.checked ? "YES" : "NO" : "NO",
                                                });
                                                memberResponses = filteredResponses;
                                              }
                                              if (!isChecked) {
                                                // Clear the date field and all dependent questions' values
                                                props.formik.setFieldValue(`${member.memberRelation}_${member.memberName}_${subQuestion.id}`, null);
                                                // Clear subsequent questions
                                                subQuestion.options.forEach(option => {
                                                  option.nextQuestions.forEach(nextSubQuestion => {
                                                    props.formik.setFieldValue(`${member.memberRelation}_${member.memberName}_${nextSubQuestion.id}`, null);
                                                  });
                                                });
                                              }
                                              updatedArray[member.memberRelation] = filteredResponses;

                                              return updatedArray;
                                            });
                                            props.formik.setFieldValue(`${`${member.memberRelation}_${member.memberName}_${subQuestion.id}`}`, isChecked ? "YES" : "NO");
                                            if (!isChecked) {
                                              props.formik.setFieldValue(`${member.memberRelation}_${member.memberName}_${subQuestion.id}`, ""); // Clear the date field
                                            }
                                          }}
                                        />
                                        <label className="label-text">{subQuestion.question}</label>
                                      </div>
                                      {props.formik.values[`${`${member.memberRelation}_${member.memberName}_${subQuestion.id}`}`] == "YES" && Array.isArray(subQuestion.options) && subQuestion.options.length > 0 && (
                                        <div className="next-questions">
                                          {subQuestion.options.map((option, optionIndex) => (
                                            <div key={optionIndex}>

                                              {Array.isArray(option.nextQuestions) && option.nextQuestions.length > 0 &&
                                                option.nextQuestions.map((subQuestion, subIndex) => (
                                                  <div key={subIndex} className=" mt-2 mb-2">
                                                    <div className="checkbox-wrap" style={{ width: '990px' }}>

                                                      {subQuestion.type === "date" ?
                                                        <>
                                                          <input
                                                            type="text"
                                                            value={props.formik.values[`${member.memberRelation}_${member.memberName}_${subQuestion.id}`] || ''}
                                                            onChange={(e) => {
                                                              let inputValue = e.target.value;

                                                              if (!/^[0-9/]*$/.test(inputValue)) {
                                                                console.log("Invalid characters. Only numbers and '/' are allowed.");
                                                                return;
                                                              }

                                                              inputValue = inputValue.replace(/[^0-9]/g, '');
                                                              if (inputValue.length > 2 && inputValue.length <= 4) {
                                                                inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
                                                              } else if (inputValue.length > 4) {
                                                                inputValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2, 6);
                                                              }

                                                              console.log("Date Input Value:", inputValue);

                                                              setQuestionAnswerArray((prevArray) => {
                                                                const updatedArray = { ...prevArray };

                                                                const memberResponses = updatedArray[member.memberRelation] || [];
                                                                const filteredResponses = memberResponses.filter(
                                                                  (item) => item.questionCd !== subQuestion.questionCd
                                                                );

                                                                if (inputValue) {
                                                                  filteredResponses.push({
                                                                    questionSetCd: subQuestion.questionSetCd,
                                                                    questionCd: subQuestion.questionCd,
                                                                    response: inputValue
                                                                  });
                                                                }

                                                                updatedArray[member.memberRelation] = filteredResponses;
                                                                return updatedArray;
                                                              });

                                                              props.formik.setFieldValue(`${member.memberRelation}_${member.memberName}_${subQuestion.id}`, inputValue);
                                                            }}
                                                            onBlur={(e) => {
                                                              const regex = /^(0[1-9]|1[0-2])\/(\d{4})$/;
                                                              const inputValue = e.target.value;

                                                              if (!regex.test(inputValue)) {
                                                                console.log("Invalid date format. Please enter in MM/YYYY.");
                                                                formContext.notifyError("Invalid date format. Please enter in MM/YYYY");
                                                                props.formik.setFieldError(
                                                                  `${member.memberRelation}_${member.memberName}_${subQuestion.id}`,
                                                                  "Date must be in MM/YYYY format"
                                                                );
                                                                return;
                                                              }

                                                              const [month, year] = inputValue.split("/").map(Number);
                                                              const currentDate = new Date();
                                                              const inputDate = new Date(year, month - 1);

                                                              if (inputDate > currentDate) {
                                                                console.log("Date cannot be in the future.");
                                                                formContext.notifyError("Date cannot be in the future.");
                                                                props.formik.setFieldError(
                                                                  subQuestion.id,
                                                                  "Date cannot be in the future"
                                                                );
                                                                return;
                                                              }

                                                              const dob = new Date(props?.formik?.values?.kycNomineDob);
                                                              const dobDate = new Date(dob.getFullYear(), dob.getMonth());
                                                              const inputDateWithoutTime = new Date(inputDate.getFullYear(), inputDate.getMonth());
                                                              console.log("dob", dobDate);

                                                              if (inputDateWithoutTime < dobDate) {
                                                                console.log("Date cannot be before the date of birth.");
                                                                formContext.notifyError("Date cannot be before the date of birth.");
                                                                props.formik.setFieldError(
                                                                  `${member.memberRelation}_${member.memberName}_${subQuestion.id}`,
                                                                  "Date cannot be before the date of birth"
                                                                );
                                                                return;
                                                              }

                                                              console.log("Valid date input.");
                                                            }}
                                                            placeholder="MM/YYYY"
                                                            style={{
                                                              width: "200px",
                                                              height: "40px",
                                                              borderRadius: "8px",
                                                              border: "1px solid #e5e5ea",
                                                              padding: "5px",
                                                              boxSizing: "border-box",
                                                              margin: '0px 30px 10px'
                                                            }}
                                                          />

                                                          {props.formik.errors[`${member.memberRelation}_${member.memberName}_${subQuestion.id}`] && props.formik.touched[`${member.memberRelation}_${member.memberName}_${subQuestion.id}`] && (
                                                            <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                              {props.formik.errors[`${member.memberRelation}_${member.memberName}_${subQuestion.id}`]}
                                                            </div>
                                                          )}
                                                        </>
                                                        : <input
                                                          type="text"
                                                          value={props.formik.values[`${member.memberRelation}_${member.memberName}_${subQuestion.id}`] || ""}
                                                          onChange={(e) => {
                                                            console.log(`Changed text input for sub-question ${subQuestion.id}`);
                                                            setQuestionAnswerArray((prevArray) => {
                                                              const updatedArray = { ...prevArray };
                                                              const memberResponses = updatedArray[member.memberRelation] || [];
                                                              const filteredResponses = memberResponses.filter(
                                                                (item) => item.questionCd !== subQuestion.questionCd
                                                              );

                                                              filteredResponses.push({
                                                                questionSetCd: subQuestion.questionSetCd,
                                                                questionCd: subQuestion.questionCd,
                                                                response: props.formik.values[`${`${member.memberRelation}_${member.memberName}_${subQuestion.id}`}`] ? e.target.value : "NO",
                                                              });

                                                              updatedArray[member.memberRelation] = filteredResponses;
                                                              return updatedArray;
                                                            });

                                                            console.log("subQuestion.id", subQuestion.id);
                                                            props.formik.setFieldValue(`${member.memberRelation}_${member.memberName}_${subQuestion.id}`, e.target.value);
                                                          }}
                                                          placeholder={subQuestion.question}
                                                          style={{
                                                            width: '200px',
                                                            height: '40px',
                                                            borderRadius: '8px',
                                                            border: '1px solid #e5e5ea',
                                                            padding: '5px',
                                                            boxSizing: 'border-box',
                                                            margin: '0px 30px 10px'
                                                          }}
                                                        />
                                                      }
                                                    </div>
                                                  </div>
                                                ))}
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                    </div>
                                  ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </Row>
                    </Card>
                  );
                })

              )}
            </Col>
          </Row>
        </Card>
      ))}

      {/* Display the next common question after all sub-questions are answered */}
      {Object.keys(answeredQuestions).length === questions.length && (
        <div className="next-question-notification">
        </div>
      )}
    </div>
  );

}

export default MedicalQuestionnaire;