import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import InputFieldRadio from "../../commonModules/InputFieldRadio";
import Arrow from "../../../images/down-arrow.svg";
import UseFormContext from "../../../context/UseFormContext";
import SVG from "react-inlinesvg";

const RiderSelection = (props) => {
  const { id } = useParams();
  const formContext = UseFormContext();
  const [riders, setRiders] = useState([]);
  let history = useHistory();

  // Default riders
  const defaultRiders = [
    { name: 'Unlimited Automatic Recharge', amount: "", riderCode: "UARV1195", riderId: 1, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
    { name: 'Pre and Post Hospitalization', amount: 0, riderCode: "PREPHECA1150", riderId: 11, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
    { name: 'Unlimited E-Consultation', amount: 0, riderCode: "UECV1153", riderId: 12, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
  ];

  const getAllRiders = () => {
    const selectedRiders = formContext.selectedRiders || [];
    const ridersFromHealthQuote = formContext.selectedHealthQuoteData?.riderList || [];
    let mergedRiders = [
      ...defaultRiders, 
      ...selectedRiders.filter(selectedRider => !defaultRiders.some(defaultRider => defaultRider.riderId === selectedRider.riderId)), // Only non-default selected riders
    ];

    const eldestMemberAge = Math.max(
      ...formContext.selectedHealthQuoteData?.healthInsurefor.map((member) => member.age) || [0]
    );
    const zone = formContext.selectedHealthQuoteData?.zone || "";

    if (eldestMemberAge <= 45 && (zone === "zone 1" || zone === "zone 1a")) {
      console.log("Automatically adding NCB Super based on Zone and Age");
      const ncbSuperRider = ridersFromHealthQuote.find(rider => rider.name === "NCB Super");
      if (ncbSuperRider && !selectedRiders.some(rider => rider.riderId === ncbSuperRider.riderId)) {
        selectedRiders.push(ncbSuperRider);
      }
    } else if (eldestMemberAge >= 76 && eldestMemberAge <= 99) {
      console.log("Automatically adding NCB Super based on Age between 76 and 99");
      const ncbSuperRider = ridersFromHealthQuote.find(rider => rider.name === "NCB Super");
      if (ncbSuperRider && !selectedRiders.some(rider => rider.riderId === ncbSuperRider.riderId)) {
        selectedRiders.push(ncbSuperRider);
      }
    }

    mergedRiders = mergedRiders.map((rider) => {
      const selectedRider = ridersFromHealthQuote.find((quoteRider) => quoteRider.riderCode === rider.riderCode);
      if (selectedRider) {
        rider.amount = selectedRider.amount;
      }
      return rider;
    });

    const uniqueRiders = Array.from(new Map(mergedRiders.map(rider => [rider.riderId, rider])).values());
    return uniqueRiders;
  };

  useEffect(() => {
    if (formContext.selectedHealthQuoteData && formContext.selectedHealthQuoteData.riderList) {
      setRiders(formContext.selectedHealthQuoteData.riderList);
    }
  }, [formContext.selectedHealthQuoteData]);

  const hospitalLimitValues = [
    { label: "Select", value: "" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
  ];

  const getLabelForSumInsured = (value) => {
    const limit = hospitalLimitValues.find(item => item.value === value);
    return limit ? limit.label : "Not specified";
  };

  const filteredRiders = riders.filter(rider => rider.amount !== 0 && rider.amount !== "");
  const ridersList = filteredRiders.filter(rider => rider.type === "M");
  const addOnsList = filteredRiders.filter(rider => rider.type === "O");

  const formatAmount = (amount) => {
    if (amount) {
      return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(amount);
    }
    return "0";
  };

  const handleAddOrRemove = (item, type) => {
    if (type === "rider") {
      if (formContext.setSelectedRiders) {
        formContext.setSelectedRiders((prevState) => {
          const updatedRiders = [...prevState];
          const existingRiderIndex = updatedRiders.findIndex((r) => r.riderId === item.riderId);
          if (existingRiderIndex === -1) {
            updatedRiders.push(item); // Add the rider
          } else {
            updatedRiders.splice(existingRiderIndex, 1); // Remove the rider
          }
          return updatedRiders;
        });
      } else {
        console.error('setSelectedRiders is not available');
      }
    } else if (type === "addon") {
      if (formContext.setSelectedAddOns) {
        formContext.setSelectedAddOns((prevState) => {
          const updatedAddOns = [...prevState];
          const existingAddOnIndex = updatedAddOns.findIndex((a) => a.riderId === item.riderId);
          if (existingAddOnIndex === -1) {
            updatedAddOns.push(item); // Add the add-on
          } else {
            updatedAddOns.splice(existingAddOnIndex, 1); // Remove the add-on
          }
          return updatedAddOns;
        });
      } else {
        console.error('setSelectedAddOns is not available');
      }
    }
  };

  const isRiderAdded = (riderId) => {
    return formContext.selectedRiders && formContext.selectedRiders.some((r) => r.riderId === riderId);
  };

  const isAddOnAdded = (riderId) => {
    return formContext.selectedAddOns && formContext.selectedAddOns.some((a) => a.riderId === riderId);
  };

  const backButtonPressed = () => {
    formContext.setgetquotesApiFlag(false);
    history.push("/healthQuotes/1?");
  };

  return (
    <div>
      {/* Cover Amount Section */}
      <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
        <Row>
          <Col sm={12} md={6} lg={6} className="pt-0">
            <label className="mb-1 fw-600">Cover Amount</label>
            <p>{getLabelForSumInsured(props.formik.values.sumInsured) || "Not specified"}</p>
          </Col>
          <Col sm={12} md={6} lg={6} className="pt-0">
            <label className="mb-1 fw-600">Policy Period</label>
            <p>{props.formik.values.policyTerm} Year</p>
          </Col>
        </Row>
      </div>

      <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Riders</label>
        <p className="text-grey mb-4">Consider these optional riders to further enhance your coverage.</p>
        <div className="scroll-box">
          <div className="border-wrap-box">
            <h6 className="bordered-title">Select any 1 of 2</h6>
            <div className="addons-list-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {getAllRiders().length > 0 ? (
                getAllRiders().map((rider) => (
                  <div className="single-box" key={rider.riderId}>
                    <Row className="align-items-center">
                      <Col lg={7}>
                        <div className="policy-details">
                          <h6 className="mb-1 fs-13 fw-600">{rider.name}</h6>
                          <p className="text-grey mb-0">{rider.description}</p>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div className="policy-cost">
                          <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                          <p className="mb-0"><strong>₹ {formatAmount(rider.amount)}</strong></p>
                        </div>
                      </Col>
                      <Col lg={2}>
                        {/* {old} */}
                        {/* <Button
                          className="primary-btn float-end"
                          size="xs"
                          onClick={() => handleAddOrRemove(rider, "rider")}
                          disabled={defaultRiders.some(defaultRider => defaultRider.riderId === rider.riderId)} // Disable if default rider>
                          {defaultRiders.some(defaultRider => defaultRider.riderId === rider.riderId) ? "Default" : (isRiderAdded(rider.riderId) ? "Added" : "+ Add")}
                        </Button> */}

                        <Button
                          className="primary-btn float-end"
                          size="xs"
                          onClick={() => handleAddOrRemove(rider, "rider")}
                          disabled={defaultRiders.some(defaultRider => defaultRider.riderId === rider.riderId) || isRiderAdded(rider.riderId)} 
>
                          {defaultRiders.some(defaultRider => defaultRider.riderId === rider.riderId) ? "Default" : (isRiderAdded(rider.riderId) ? "Added" : "+ Add")}
                        </Button>

                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p>No riders available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiderSelection;