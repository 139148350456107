import axios from "axios";
import { DateConvertFunction } from "../components/commonModules/CommonCode";
import { convertDate, gateage } from "../components/commonModules/CommonCode";
import { bikeData } from "../mockDataFolder/vahanVehicleType";
import FormContext from "../context/FormContext";
import { careBankDetailsEncrypt } from "./kyc/care-kyc.service";
export const rtoBaseUrl = process.env.REACT_APP_RTO_API_URL;
export const rtoLoginUrl = `login`;
export const rtoUsername = process.env.REACT_APP_RTO_API_USERNAME;
export const rtoPassword = process.env.REACT_APP_RTO_API_PASSWORD;
export const projectBaseurl = process.env.REACT_APP_BASE_URL;
export const getCitiesUrl = `/cxf/configmanager/config/getconfigdata`;
export const createQote = `/cxf/authrestservices/integrate/invoke`;
export const getRtodetails = "/cxf/authrestservices/integrate/invoke";
export const getpolicyName = "/cxf/Insuranceservice/request/integrate/invoke";
export const kycBaseurl = `/cxf/kycservice/request/integrate/invoke`;
export const addressUrl = `https://api.postalpincode.in/pincode/`;
export const mobileNumberurl = `/cxf/Otpservice/request/integrate/invoke`;
//export const policyDownloadRepositoryUrl = 'https://uat-mservices.policies365.com/cxf/authrestservices/integrate/invoke'
export const policyDownloadRepositoryUrl = '/cxf/authrestservices/integrate/invoke'
// export const createCarPolicyURL = "https://uat-mservices.policies365.com/cxf/authrestservices/integrate/invoke"
export const createCarPolicyURL = "/cxf/authrestservices/integrate/invoke"
export const createBikePolicyURL = "/cxf/authrestservices/integrate/invoke"
// to login rto with username and password
export const policyStatus = `/cxf/authrestservices/integrate/invoke`

//added-health
export const healthQuoteServiceURL = `/cxf/authrestservices/integrate/invoke`;
export const proposalHealthURL = `/cxf/authrestservices/integrate/invoke`;

//pincode 
export const pincodeURL = `/cxf/authrestservices/integrate/invoke`


export function rtoLogin() {
  const data = {
    request: {
      username: rtoUsername,
      password: rtoPassword,
    },

    URL: `${rtoBaseUrl}/login`,
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}
// to get data from rto about vechicle
export function rtoGetData(userId, id, searchParameter) {
  const rtoLoginGetData = `${userId}/vehicleregistrations/?access_token=${id}`;
  const data = {
    request: {
      task: "detailedSearch",
      essentials: {
        vehicleNumber: searchParameter,
        signzyID: "AKYTSZY",
      },
    },

    URL: rtoBaseUrl + rtoLoginGetData,
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

// to get cities when popup appears eg mh-12pune
export function getCities(filterData, city) {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      "Access-Control-Allow-Headers": "Origin, Content-Type",
      "Access-Control-Allow-Credentials": true,
    },
  };
  return axios.get(
    projectBaseurl + getCitiesUrl + `?filter=${filterData}&q=${city}`
  );
}

// to create Quotes on bike  page
export function createQoteApi(values, idv = 0, prevQuoteId) {
  const currentDate = DateConvertFunction(new Date(), 16);
  const tenuarDate = DateConvertFunction(
    new Date(),
    17,
    Number(values?.policyTerms)
  );

  const planType =
    values?.insuranceFor === "new"
      ? "OD-TP-5"
      : values?.policyType + "-" + values?.policyTerms;
  let PreviousPolicyExpiryDate;
  if (values?.insuranceFor === "new") {
    PreviousPolicyExpiryDate = null;
  } else {
    PreviousPolicyExpiryDate = values?.expiryDate
      ? convertDate(values?.expiryDate, 1)
      : values?.previousPolicy;
  }
  let tpDates = {
    TPPolicyExpiryDate: values?.tpPolicyEndtDate
      ? convertDate(values?.tpPolicyEndtDate, 3)
      : null,
    TPPolicyStartDate: values?.tpPolicyStartDate
      ? convertDate(values?.tpPolicyStartDate, 3)
      : null,
    ODPolicyExpiryDate: PreviousPolicyExpiryDate,
  };
  if (values?.insuranceFor === "new") {
    tpDates.TPPolicyExpiryDate = null;
    tpDates.TPPolicyStartDate = null;
    tpDates.ODPolicyExpiryDate = null;
  }

  let registrationValidityDate = "";
  if (values?.insuranceFor === "new") {
    let newDate = parseInt(values.registrationYear) + 15;
    registrationValidityDate = "01/01/" + newDate;
  } else if (values?.insuranceFor === "renew") {
    let newDate = new Date(values.registrationYear);
    newDate.setFullYear(newDate.getFullYear() + 15);
    let day = newDate.getDate().toString().padStart(2, "0");
    let month = (newDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    let year = newDate.getFullYear();
    registrationValidityDate = `${day}/${month}/${year}`;
  }

  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "getBikeQuote",
    },
    body: {
      quoteParam: {
        ncb: Number(values?.noClaimBonus),
        ownedBy: values.vehicalRegType ? values.vehicalRegType : "Individual",
        policyType: values?.insuranceFor ? values?.insuranceFor : "renew",
        planType: planType,
        riders: values?.addOnCover ? values?.addOnCover : [],
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        vahanData: "demo",
      },
      vehicleInfo: {
        IDV: Number(idv),
        RTOCode: values?.registeredRto?.slice(0, 4).toUpperCase(),
        previousClaim: values?.insuranceClaim,
        make: values?.make,
        model: values?.model,
        variant: values?.varient,
        registrationPlace: values?.registeredRto,
        PreviousPolicyExpiryDate: PreviousPolicyExpiryDate,
        dateOfRegistration: convertDate(values?.registrationYear, 1),
        best_quote_id: idv > 0 ? prevQuoteId : "",
        TPPolicyExpiryDate: tpDates.TPPolicyExpiryDate,
        TPPolicyStartDate: tpDates.TPPolicyStartDate,
        ODPolicyExpiryDate: tpDates.ODPolicyExpiryDate,
        policyStartDate: currentDate,
        policyExpiryDate: tenuarDate,
        registrationValidityDate,
      },
      campaign_id: "ce9a676c-af21-d810-7f96-5c98bf17d9f6",
    },
  };
  if (sessionStorage.getItem("id")) {
    data.body.quoteParam["agentId"] = sessionStorage.getItem("id");
  }
  if (sessionStorage.getItem("agencyId")) {
    data.body.quoteParam["agencyId"] = sessionStorage.getItem("agencyId");
  }
  if (sessionStorage.getItem("branchId")) {
    data.body.quoteParam["branchId"] = sessionStorage.getItem("branchId");
  }
  // data.body.quoteParam["dealId"]="DL-3005/1485788"

  if (
    localStorage.getItem("dealId") &&
    localStorage.getItem("dealId") !== null &&
    localStorage.getItem("dealId") !== undefined
  ) {
    data.body.quoteParam["dealId"] = localStorage.getItem("dealId");
  }
  return axios.post(projectBaseurl + createQote, data);
}

// to create Quotes on bike and car page
export function createQoteApiCar(values, idv = 0, prevQuoteId) {
  const planType =
    values?.insuranceFor === "new" ? "OD-TP-3" : values?.policyType;
  let PreviousPolicyExpiryDate;
  if (values?.insuranceFor === "new") {
    PreviousPolicyExpiryDate = null;
  } else {
    PreviousPolicyExpiryDate = values?.expiryDate
      ? convertDate(values?.expiryDate, 1)
      : values?.previousPolicy;
  }
  let tpDates = {
    TPPolicyExpiryDate: values?.tpPolicyEndtDate
      ? convertDate(values?.tpPolicyEndtDate, 3)
      : null,
    TPPolicyStartDate: values?.tpPolicyStartDate
      ? convertDate(values?.tpPolicyStartDate, 3)
      : null,
    ODPolicyExpiryDate: PreviousPolicyExpiryDate,
  };
  if (values?.insuranceFor === "new") {
    tpDates.TPPolicyExpiryDate = null;
    tpDates.TPPolicyStartDate = null;
    tpDates.ODPolicyExpiryDate = null;
  }

  let registrationValidityDate = "";
  if (values?.insuranceFor === "new") {
    let newDate = parseInt(values.registrationYear) + 15;
    registrationValidityDate = "01/01/" + newDate;
  } else if (values?.insuranceFor === "renew") {
    let newDate = new Date(values.registrationYear);
    newDate.setFullYear(newDate.getFullYear() + 15);
    let day = newDate.getDate().toString().padStart(2, "0");
    let month = (newDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    let year = newDate.getFullYear();
    registrationValidityDate = `${day}/${month}/${year}`;
  }

  const data = {
    header: {
      messageId: "",
      source: "web",
      deviceId: "web",
      browser: "Chrome V - 120",
      transactionName: "carQuote",
    },
    body: {
      vehicleInfo: {
        IDV: Number(idv),
        PreviousPolicyExpiryDate: PreviousPolicyExpiryDate,
        RTOCode: values?.registeredRto?.slice(0, 4).toUpperCase(),
        dateOfRegistration: convertDate(values?.registrationYear, 1),
        idvOption: 1,
        previousClaim: values?.insuranceClaim,
        registrationPlace: values?.registeredRto,
        make: values?.make,
        model: values?.model,
        variant: values?.varient,
        fuel: values?.fuel,
        cubicCapacity: "",
        best_quote_id: idv > 0 ? prevQuoteId : "",
        TPPolicyExpiryDate: tpDates.TPPolicyExpiryDate,
        TPPolicyStartDate: tpDates.TPPolicyStartDate,
        ODPolicyExpiryDate: tpDates.ODPolicyExpiryDate,
        registrationValidityDate,
      },
      quoteParam: {
        ncb: Number(values?.noClaimBonus),
        ownedBy: values?.ownedBy ? values?.ownedBy : "Individual",
        policyType: values?.insuranceFor ? values?.insuranceFor : "renew",
        quoteType: "3",
        riders: values?.addOnCover ? values?.addOnCover : [],

        planType: planType,
        occupationId: "1",
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        vahanData: "demo",
      },
      requestSource: "web",
      messageId: "",
    },
  };
  if (sessionStorage.getItem("id")) {
    data.body.quoteParam["agentId"] = sessionStorage.getItem("id");
  }
  if (sessionStorage.getItem("agencyId")) {
    data.body.quoteParam["agencyId"] = sessionStorage.getItem("agencyId");
  }
  if (sessionStorage.getItem("branchId")) {
    data.body.quoteParam["branchId"] = sessionStorage.getItem("branchId");
  }
  // data.body.quoteParam["dealId"]="DL-3005/1485788"
  if (
    localStorage.getItem("dealId") &&
    localStorage.getItem("dealId") !== null &&
    localStorage.getItem("dealId") !== undefined
  ) {
    data.body.quoteParam["dealId"] = localStorage.getItem("dealId");
  }
  return axios.post(projectBaseurl + createQote, data);
}

// to get Quotes on Quotes page bike/car
export function getQoteApi(qname, messageId, QUOTE_ID, transactionName) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName:
        transactionName === "bike" ? "getBikeQuoteResult" : "getCarQuoteResult",
    },
    body: {
      qname: qname,
      messageId: messageId,
      QUOTE_ID: QUOTE_ID,
    },
  };

  return axios.post(projectBaseurl + createQote, data);
}

// export function createHealthQuoteApi(values, zone) {
//   console.log(zone);
//   console.log("Display values:", JSON.stringify(values, null, 2));

//   let healthInsurefor = Array.isArray(values.healthInsurefor)
//     ? values.healthInsurefor
//     : JSON.parse(values.healthInsurefor || "[]");

//   const numberOfFamilyMembers = healthInsurefor.length;

//   function calculateNumberOfChildren(members) {
//     return members.filter((member) => calculateAge(member.dateOfBirth) <= 24).length;
//   }

//   // Determine plan type
//   let planType;
//   if (numberOfFamilyMembers === 1) {
//     planType = "I";
//   } else if (numberOfFamilyMembers > 2) {
//     planType = "F";
//   } else {
//     planType = "F";
//   }

//   // Function to calculate age based on DOB-old
//   function calculateAge(dob) {
//     const birthDate = new Date(dob);
//     const today = new Date();
//     let age = today.getFullYear() - birthDate.getFullYear();
//     const monthDiff = today.getMonth() - birthDate.getMonth();

//     if (
//       monthDiff < 0 ||
//       (monthDiff === 0 && today.getDate() < birthDate.getDate())
//     ) {
//       age--;
//     }
//     return age;
//   }

//   function getAgeRange(age, isAnnualCheckupSelected, isChild) {
//     if (isChild) {
//       if (age <= 18) {
//         return "91 days - 18 years";
//       } else if (age >= 19 && age <= 24) {
//         return "19 - 24 years";
//       }
//     } else {
//       if (isAnnualCheckupSelected) {
//         if (age < 18) {
//           return "18 years";
//         } else if (age >= 18 && age <= 24) {
//           return "19 - 24 years";
//         } else if (age >= 25 && age <= 35) {
//           return "25 - 35 years";
//         } else if (age >= 36 && age <= 40) {
//           return "36 - 40 years";
//         } else if (age >= 41 && age <= 45) {
//           return "41 - 45 years";
//         } else if (age >= 46 && age <= 50) {
//           return "46 - 50 years";
//         } else if (age >= 51 && age <= 55) {
//           return "51 - 55 years";
//         } else if (age >= 56 && age <= 60) {
//           return "56 - 60 years";
//         } else if (age >= 61 && age <= 65) {
//           return "61 - 65 years";
//         } else if (age >= 66 && age <= 70) {
//           return "66 - 70 years";
//         } else if (age >= 71 && age <= 75) {
//           return "71 - 75 years";
//         } else if (age >= 76 && age <= 80) {
//           return "76 - 80 years";
//         } else {
//           return "> 80 years";
//         }
//       } else {
//         if (age < 1) {
//           return "91 days - 18 years";
//         } else if (age <= 5) {
//           return "1 - 4 years";
//         } else if (age <= 17) {
//           return "5 - 17 years";
//         } else if (age <= 24) {
//           return "18 - 24 years";
//         } else if (age <= 35) {
//           return "25 - 35 years";
//         } else if (age <= 40) {
//           return "36 - 40 years";
//         } else if (age <= 45) {
//           return "41 - 45 years";
//         } else if (age <= 50) {
//           return "46 - 50 years";
//         } else if (age <= 55) {
//           return "51 - 55 years";
//         } else if (age <= 60) {
//           return "56 - 60 years";
//         } else if (age <= 65) {
//           return "61 - 65 years";
//         } else if (age <= 70) {
//           return "66 - 70 years";
//         } else if (age <= 75) {
//           return "71 - 75 years";
//         } else if (age <= 80) {
//           return "76 - 80 years";
//         } else {
//           return "> 80 years";
//         }
//       }
//     }
//   }

//   const isAnnualCheckupSelected = values?.ridersList?.some(rider => rider.name === "Annual Health Check-up");

//   const hasChildren = healthInsurefor.some((member) => calculateAge(member.dateOfBirth) <= 24);

//   healthInsurefor = healthInsurefor.map((member) => {
//     const age = calculateAge(member.dateOfBirth);
//     const isChild = hasChildren && age <= 24;  
//     const ageRange = getAgeRange(age, isAnnualCheckupSelected, isChild);  
//     return {
//       ...member,
//       gender: values?.gender || "unknown",
//       age: age,
//       ageRange: ageRange,
//     };
//   });
//   const eldestMemberAge = Math.max(...healthInsurefor.map((member) => member.age));
//   const eldestMemberAgeWithoutRange = eldestMemberAge;
//   const eldestMemberAgeRange = getAgeRange(eldestMemberAge, isAnnualCheckupSelected, false);

//   let newMembersAgeRanges = {}; 

//   if (numberOfFamilyMembers > 1 && isAnnualCheckupSelected) {
//     const sortedMembers = healthInsurefor
//       .sort((a, b) => b.age - a.age);

//     for (let i = 1; i < sortedMembers.length; i++) {
//       const anotherMember = sortedMembers[i];
//       if (anotherMember) {
//         const dynamicKey = `newMemberAge${i}`; 
//         newMembersAgeRanges[dynamicKey] = getAgeRange(anotherMember.age, isAnnualCheckupSelected, false);
//       }
//     }
//   }

//   const numberOfChildren = calculateNumberOfChildren(healthInsurefor);
//   const policyTerm = values?.policyTerm || 1;

//   const sysPolicyStartDate = new Date();
//   const sysPolicyEndDate = new Date(sysPolicyStartDate);
//   if (policyTerm === 1) {
//     sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 1);
//   } else if (policyTerm === 2) {
//     sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 2);
//   } else if (policyTerm === 3) {
//     sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 3);
//   } else {
//     sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 1);
//   }

//   function formatDateToDDMMYYYY(date) {
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   }

//   // Add global coverage addon if it is the first time the API is called
//   const ridersList = values?.ridersList || [];
//   const sumInsuredforRList = values?.sumInsured;
//   if (ridersList.length === 0 && (zone === "Zone 1" || zone === "Zone 1A") && eldestMemberAge <= 45) {
//     if (!ridersList.some(rider => rider.name === "NCB Super")) {
//       ridersList.push({
//         "riderId": 2,
//         "name": "NCB Super",
//         "description": "Get your sum insured increased up to 150% in 5 consecutive claim free years",
//         "type": "O",
//         "riderCode": "CAREADWITHNCB"
//       });
      
//     }
//   } else if (eldestMemberAge >= 76 && eldestMemberAge <= 99) {
//     if (!ridersList.some(rider => rider.name === "NCB Super")) {
//       ridersList.push({
//         "riderId": 2,
//         "name": "NCB Super",
//         "description": "Get your sum insured increased up to 150% in 5 consecutive claim free years",
//         "type": "O",
//         "riderCode": "CAREADWITHNCB"
//       });
//     }
//   }

//   if (sumInsuredforRList >= 200) {
//     if (!ridersList.some(rider => rider.name === "Global Coverage")) {
//       ridersList.push({
//         "riderCode": "NEGCE4092",
//         "riderId": 13,
//         "name": "Global Coverage",
//         "description": "Global Coverage Add-on: Extends health insurance benefits internationally, covering medical expenses incurred outside India.",
//         "type": "o",
//         "value": "WW Excl. US and Canada"
//       });
//     }
//   }

//   const healthInfo = {
//     existingIllness: values?.existanceIllness,
//     firstName: values?.firstName,
//     lastName: values?.lastName,
//     sumInsured: values?.sumInsured,
//     policyType: "new",
//     emailId: values?.email || "",
//     pinCode: values?.pincode,
//     mobileNumber: values?.phoneNumber,
//     planType: planType,
//     healthInsurefor: healthInsurefor,
//     eldestMemberAge: eldestMemberAgeRange,
//     eldestMemberAgeWithoutRange: eldestMemberAgeWithoutRange, // Add this new field
//     anotherMemberAge: newMembersAgeRanges,
//     numberOfChildren: numberOfChildren,
//     numberOfFamilyMembers: numberOfFamilyMembers,
//     existingIllnessDetails: values?.illnesses || {},
//     policyTerm: policyTerm,
//     ridersList: ridersList,
//   };

//   if (zone) {
//     healthInfo['zone'] = zone;
//   }

//   const data = {
//     header: {
//       messageId: "uniqueMessageId123",
//       source: "web",
//       deviceId: "web",
//       browser: "Chrome V - 120",
//       transactionName: "getHealthQuote",
//     },
//     body: {
//       healthInfo,
//       systemPolicyStartDate: {
//         sysPolicyStartDate: formatDateToDDMMYYYY(sysPolicyStartDate),
//         sysPolicyEndDate: formatDateToDDMMYYYY(sysPolicyEndDate), // End date in dd/mm/yyyy
//       },
//     },
//   };

//   const agentId = sessionStorage.getItem("id") || "";
//   const agencyId = sessionStorage.getItem("agencyId") || "";
//   const branchId = sessionStorage.getItem("branchId") || "";

//   // Add session storage values to healthInfo
//   if (agentId) {
//     data.body.healthInfo.agentId = agentId;
//   }
//   if (agencyId) {
//     data.body.healthInfo.agencyId = agencyId;
//   }
//   if (branchId) {
//     data.body.healthInfo.branchId = branchId;
//   }

//   console.log("Request data:", JSON.stringify(data, null, 2));

//   return axios
//     .post(projectBaseurl + healthQuoteServiceURL, data)
//     .then((response) => {
//       console.log("Response:", response.data);
//       return response.data;
//     })
//     .catch((error) => {
//       console.error("Error:", error.response ? error.response.data : error.message);
//       throw error;
//     });
// }

export function createHealthQuoteApi(values, zone) {
  console.log(zone);
  console.log("Display values:", JSON.stringify(values, null, 2));

  let healthInsurefor = Array.isArray(values.healthInsurefor)
    ? values.healthInsurefor
    : JSON.parse(values.healthInsurefor || "[]");

  const numberOfFamilyMembers = healthInsurefor.length;

  function calculateNumberOfChildren(members) {
    return members.filter((member) => calculateAge(member.dateOfBirth) <= 24)
      .length;
  }

  // Determine plan type
  let planType;
  if (numberOfFamilyMembers === 1) {
    planType = "I";
  } else if (numberOfFamilyMembers > 2) {
    planType = "F";
  } else {
    planType = "F";
  }

  // // Function to calculate age based on DOB-old
  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  // Function to get the age range based on age
  function getAgeRange(age) {
    if (age < 1) return "1 - 12 months"
    if (age <= 5) return "1 - 4 years"
    if (age <= 17) return "5 - 17 years";
    if (age <= 24) return "18 - 24 years";
    if (age <= 35) return "25 - 35 years";
    if (age <= 40) return "36 - 40 years";
    if (age <= 45) return "41 - 45 years";
    if (age <= 50) return "46 - 50 years";
    if (age <= 55) return "51 - 55 years";
    if (age <= 60) return "56 - 60 years";
    if (age <= 65) return "61 - 65 years";
    if (age <= 70) return "66 - 70 years";
    if (age <= 75) return "71 - 75 years";
    if (age <= 80) return "76 - 80 years";
    return "> 80 years";
  }

  // Calculate ages and age ranges for each family member
  healthInsurefor = healthInsurefor.map((member) => {
    const age = calculateAge(member.dateOfBirth);
    const ageRange = getAgeRange(age);
    return {
      ...member,
      gender: values?.gender || "unknown",
      age: age,
      ageRange: ageRange,
    };
  });

  // Calculate eldest member age
  const eldestMemberAge = Math.max(
    ...healthInsurefor.map((member) => member.age)
  );
  const eldestMemberAgeRange = getAgeRange(eldestMemberAge);

  const numberOfChildren = calculateNumberOfChildren(healthInsurefor);
  const policyTerm = values?.policyTerm || 1;

  const sysPolicyStartDate = new Date();

  const sysPolicyEndDate = new Date(sysPolicyStartDate);
  if (policyTerm === 1) {
    sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 1);
  } else if (policyTerm === 2) {
    sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 2);
  } else if (policyTerm === 3) {
    sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 3);
  } else {
    sysPolicyEndDate.setFullYear(sysPolicyEndDate.getFullYear() + 1);
  }

  function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Add global coverage addon if it is the first time the API is called
  const ridersList = values?.ridersList || [];
  const sumInsuredforRList = values?.sumInsured;
  if (ridersList.length === 0  && (zone === "Zone 1" || zone === "Zone 1A") && eldestMemberAge <= 45) {
    if (!ridersList.some(rider => rider.name === "NCB Super")) {
      ridersList.push({
        "riderId": 2,
        "name": "NCB Super",
        "description": "Get your sum insured increased up to 150% in 5 consecutive claim free years",
        "type": "O",
        "riderCode": "CAREADWITHNCB"
      });
    }
  } else if (eldestMemberAge >= 76 && eldestMemberAge <= 99) {
    if (!ridersList.some(rider => rider.name === "NCB Super")) {
      ridersList.push({
        "riderId": 2,
        "name": "NCB Super",
        "description": "Get your sum insured increased up to 150% in 5 consecutive claim free years",
        "type": "O",
        "riderCode": "CAREADWITHNCB"
      });  
    }
  }

  if (sumInsuredforRList >= 200) {
    if (!ridersList.some(rider => rider.name === "Global Coverage")) {
      ridersList.push({
        "riderCode": "NEGCE4092",
        "riderId": 13,
        "name": "Global Coverage",
        "description": "Global Coverage Add-on: Extends health insurance benefits internationally, covering medical expenses incurred outside India.",
        "type": "o",
        "value": "WW Excl. US and Canada"
      });
    }
  }

  const healthInfo = {
    existingIllness: values?.existanceIllness,
    firstName: values?.firstName,
    lastName: values?.lastName,
    sumInsured: values?.sumInsured,
    policyType: "new",
    emailId: values?.email || "",
    pinCode: values?.pincode,
    mobileNumber: values?.phoneNumber,
    planType: planType,
    healthInsurefor: healthInsurefor,
    eldestMemberAge: eldestMemberAgeRange,
    numberOfChildren: numberOfChildren,
    numberOfFamilyMembers: numberOfFamilyMembers,
    existingIllnessDetails: values?.illnesses || {},
    policyTerm: policyTerm,
    ridersList: ridersList, // Use the updated ridersList
  };

  if (zone) {
    healthInfo['zone'] = zone;
  }

  const data = {
    header: {
      messageId: "uniqueMessageId123",
      source: "web",
      deviceId: "web",
      browser: "Chrome V - 120",
      transactionName: "getHealthQuote",
    },
    body: {
      healthInfo,
      systemPolicyStartDate: {
        sysPolicyStartDate: formatDateToDDMMYYYY(sysPolicyStartDate),
        sysPolicyEndDate: formatDateToDDMMYYYY(sysPolicyEndDate), // End date in dd/mm/yyyy
      },
    },
  };

  const agentId = sessionStorage.getItem("id") || "";
  const agencyId = sessionStorage.getItem("agencyId") || "";
  const branchId = sessionStorage.getItem("branchId") || "";

  // Add session storage values to healthInfo
  if (agentId) {
    data.body.healthInfo.agentId = agentId;
  }
  if (agencyId) {
    data.body.healthInfo.agencyId = agencyId;
  }
  if (branchId) {
    data.body.healthInfo.branchId = branchId;
  }

  console.log("Request data:", JSON.stringify(data, null, 2));

  return axios
    .post(projectBaseurl + healthQuoteServiceURL, data)
    .then((response) => {
      console.log("Response:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

export function getHealthQuoteApi(qname, messageId, QUOTE_ID, formik) {
  console.log("Sending request with QUOTE_ID:", QUOTE_ID);

  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "getHealthQuoteResult",
    },
    body: {
      qname: qname,
      messageId: messageId,
      QUOTE_ID: QUOTE_ID,
    },
  };

  return axios
    .post(projectBaseurl + healthQuoteServiceURL, data)
    .then((response) => {
      console.log("API response:", response.data);
      return response.data;
    })
    // return axios
    // .post(healthQuoteServiceURL, data)
    // .then((response) => {
    //   const zone = response.data?.data?.quotes?.[0]?.zone || "";
    //   formik.setFieldValue("zone", zone);
    //   console.log("API response:", response.data);
    //   console.log("zone",response.data)
    //   return response.data;
    // })
    .catch((error) => {
      console.error("Error in API call:", error);
      throw error;
    });
}

//to get questions - Medical questionnair
export function getQuestionaire(values) {
  const data = {
    header: {
      messageId: "",
      source: "web",
      deviceId: "web",
      browser: "Chrome V - 120",
      transactionName: "getHealthQuestionList",
    },

    body: {
      documentType: "DiseaseQuestion",
      carrierId: values,
      // riders: [],
    },
  };

  return axios
    .post(projectBaseurl + healthQuoteServiceURL, data)
    .then((response) => {
      console.log("getQuestionaire API response:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("getQuestionaire Error in API call:", error);
      throw error;
    });
}


//pincode - URL
export function getCarePincodeData(values) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: values,
      documentType: "CityDetails",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + pincodeURL, data);
}


//perfectly working
export function submitHealthProposalApi(formikValues, selectedRiders, singlerocord) {
  console.log("Sending request to submit health proposal", formikValues);
  localStorage.setItem("insuranceCompany", singlerocord?.insuranceCompany);
  const uniqueSelectedRiders = selectedRiders.filter((rider, index, self) =>
    index === self.findIndex((t) => t.riderCode === rider.riderCode)
  );

  const familyMembers = formikValues?.familyDetails || [];
  const isFamilyFloater =
    familyMembers.filter((member) => member?.relation !== "Self").length > 0;
  const planId = isFamilyFloater ? 2 : 1;
  const uniqueFamilyMembers = new Set();

  const insuredMemberDetails = familyMembers.map((member) => {
    const insuredMember = formikValues?.InsuredMemberDetails?.find(
      (insured) => insured?.memberRelation === member?.relation
    );

    if (insuredMember) {
      const memberSelectedDisease = insuredMember?.memberSelectedDisease;
      const memberSelectedDiseaseQuestions = member?.memberSelectedDiseaseQuestions;

      const heightInCm = member?.heightFt
        ? Math.round(member?.heightFt * 30.48 + member?.heightIn * 2.54)
        : 0;

      const heightInInches = (member?.heightFt ? member?.heightFt * 12 : 0) + (member?.heightIn || 0);
      const heightInFeet = member?.heightFt || 0;
      const memberWeight = Number(member?.weight);
      const memberArray = member?.name?.split(" ")
      const details = {
        memberFirstName: insuredMember?.memberRelation === 'Self' ? formikValues?.ownerFirstName : memberArray.slice(0, -1).join(' '),
        memberLastName: insuredMember?.memberRelation === 'Self' ? formikValues?.ownerLastName : memberArray[memberArray.length - 1] || "",
        // memberFirstName: member?.name?.split(" ")[0],
        // memberLastName: member?.name?.split(" ")[1] || "",
        memberDateOfBirth: member?.dateOfBirth,
        memberHeightInFeet: heightInFeet,
        memberHeightInInches: heightInInches,
        memberHeightInCm: heightInCm,
        memberSalutation: member?.gender === "Male" ? "MR" : "MS",
        memberWeight: memberWeight,
        memberEmailId: formikValues?.ownerEmail || "",
        memberMobileNo: formikValues?.ownerPhoneNumber,
        memberPanNumber: formikValues?.pan || formikValues?.kycNominePanNumber,
        memberpermLine1: formikValues?.permLine1 || formikValues?.ownerFlatNumber,
        memberpermLine2: formikValues?.permLine2 || formikValues?.ownerAddress,
        memberpermLine3: formikValues?.permLine3 || formikValues?.ownerLocation,
        memberpermCity: formikValues?.permCity || formikValues?.ownerCity,
        memberpermDist: formikValues?.permDist || formikValues?.ownerCity,
        memberpermState: formikValues?.permState || formikValues?.ownerState,
        memberpermCountry: formikValues?.permCountry || "IN",
        memberpermPin: formikValues?.permPin || formikValues?.ownerPincode,
        membercorresLine1: formikValues?.corresLine1 || formikValues?.ownerFlatNumberReg,
        membercorresLine2: formikValues?.corresLine2 || formikValues?.ownerAddressReg,
        membercorresCity: formikValues?.corresCity || formikValues?.ownerCityReg,
        membercorresState: formikValues?.corresState || formikValues?.ownerStateReg,
        membercorresDist: formikValues?.corresDist || formikValues?.ownerCityReg,
        membercorresCountry: formikValues?.corresCountry || "IN",
        membercorresPin: formikValues?.corresPin || formikValues?.ownerPincodeReg,
        memberGender: member?.gender || "",
        memberRelation: member?.relation,
        memberSelectedDisease: memberSelectedDisease,
        memberSelectedDiseaseQuestions: memberSelectedDiseaseQuestions,
      };

      if (member?.relation === "Self") {
        return [
          { ...details, memberRole: "PRIMARY" },
          { ...details, memberRole: "PROPOSER", memberRelation: "Self" }
        ];
      }
      return { ...details };
    }
    return null;
  }).flat();

  const cleanedInsuredMemberDetails = insuredMemberDetails.filter(
    (member) => member !== null
  );

  const selectedQuoteRiders = formikValues?.addons?.filter((addOn) => {
    const matchingRider = uniqueSelectedRiders.find(rider => rider.riderCode === addOn.riderCode);
    if (matchingRider) {
      if (matchingRider.name === "Global Coverage") {
        addOn.riderCode = matchingRider.riderCode;
      }
      return true;
    }
    return false;
  });
  console.log("selectedQuoteRiders", selectedQuoteRiders);

  const ridersList = [
    ...(formikValues?.riders || []),
    ...(selectedQuoteRiders || []),
  ];
  const proposerDetails = {
    firstName: formikValues?.ownerFirstName,
    lastName: formikValues?.ownerLastName,
    gender: formikValues?.kycNomineGender,
    dateOfBirth: formikValues?.kycNomineDob,
    mobileNumber: formikValues?.ownerPhoneNumber,
    emailId: formikValues?.ownerEmail,
    panNumber: formikValues?.kycNominePanNumber,
    aadharNumber: formikValues?.kycNomineAadharNumber,
    maritalStatus: formikValues?.ownerMaritialStus,
    fullNameAsPerAadhar:
      formikValues?.ownerFirstName + " " + formikValues?.ownerLastName,
    personAge: formikValues?.familyDetails?.[0]?.age,
    isAddressSameAsCommun: true,
    salutation: formikValues?.ownerPrefix,
    kycBase64Code: formikValues?.kycBase64Code,
    kycDocumentType: formikValues?.kycDocumentType,
    ckycNo: formikValues?.ckycNo || "",
    ckyc: formikValues?.ckycNo ? "YES" : "NO",
    ovdkyc: formikValues?.ckycNo ? "NO" : "YES",
    IsConfirm: formikValues?.IsConfirm,
    communicationAddress: {
      comArea: formikValues?.ownerLocation,
      comPincode: formikValues?.ownerPincode,
      comCity: formikValues?.ownerLocation,
      comDisplayArea: formikValues?.ownerLocation,
      comDistrict: formikValues?.ownerLocation,
      comDisplayField: formikValues?.ownerAddress,
      comState: formikValues?.ownerState,
      comDoorNo: formikValues?.ownerFlatNumber,
    },
  };

  if (formikValues?.bankAccountNumber && formikValues?.bankIFSCCode) {
    proposerDetails.bankAccountNumber = careBankDetailsEncrypt(formikValues.bankAccountNumber);
    proposerDetails.bankIFSCCode = careBankDetailsEncrypt(formikValues.bankIFSCCode);
  }

  const nominationDetails = {
    nomPersonAge: gateage(formikValues?.nomineDob),
    nomFirstName: formikValues?.nomineFirstName,
    nomLastName: formikValues?.nomineLastName,
    nomDateOfBirth: convertDate(formikValues?.nomineDob, 3),
    nominationRelation: formikValues?.nomineRelation,
    nominationRelationId: 1,
  };

  if (gateage(formikValues?.nomineDob) < 18) {
    nominationDetails['appointeeName'] = formikValues?.appointeeName;
    nominationDetails['appointeeDOB'] = formikValues?.appointeeDOB;
    nominationDetails['appointeeRelation'] = formikValues?.appointeeRelation;
  }

  const data = {
    header: {
      transactionName: "submitHealthProposal",
      messageId: null,
      deviceId: "ABCD12345",
    },
    body: {
      policyDetails: {
        sumInsured: formikValues?.sumInsured,
        policyTerm: formikValues?.policyTerm,
        basePremium: formikValues?.basePremium,
        TotalPremium: formikValues?.TotalPremium,
        IsPort: formikValues?.IsPort,
        ridersList: uniqueSelectedRiders
          .map(rider => {
            if (rider.name === "Global Coverage" && rider.value) {
              return {
                ...rider,
                value: rider.value
              };
            }
            return rider;
          })
          .filter(rider => rider !== undefined),
        addOns: Array.from(new Set(uniqueSelectedRiders
          .map(rider => rider.riderCode)
          .filter(riderCode => riderCode !== undefined)
        )).join(",")
      },
      proposerDetails,
      nominationDetails,
      InsuredMemberDetails: cleanedInsuredMemberDetails,
      addressDetails: {
        isAddressSameAsKYC: true,
        area: formikValues?.ownerLocation,
        pincode: formikValues?.ownerPincode,
        city: formikValues?.ownerLocation,
        displayArea: formikValues?.ownerLocation,
        district: formikValues?.ownerLocation,
        displayField: formikValues?.ownerAddress,
        state: formikValues?.ownerState,
        doorNo: formikValues?.ownerFlatNumber,
      },
      carrierId: 67,
      planId: planId,
      policyType: "new",
      QUOTE_ID: formikValues?.QUOTE_ID,
      businessLineId: 4,
      campaign_id: "97c0ef04-fcac-6b03-aa58-5c0512ad1884",
    },
  };

  console.log("***data****", data);
  return axios.post(projectBaseurl + proposalHealthURL, data);
}


export function getRtoInfo() {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "getPopularRTO",
    },
    body: {
      popularRTOList: "Y",
      requestSource: "web",
      leadSource: { utm_campaign: "" },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// to get list of all riders
export function getRiderInfo() {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: "Bike",
      documentType: "Rider",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

//to create lead for policy
export function createLead(values, props) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V - 74",
      transactionName: "createLead",
    },
    body: {
      quoteParam: {
        insuranceType: 1,
        ncb: 25,
        owneredBy: "Individual",
        personAge: 40,
        policyType: "renew",
        zone: "ZoneA",
        customerpinCode: "",
        customerCity: "",
        customerState: "",
        documentType: "QuoteRequest",
        quoteType: 2,
        policyExpiredAge: 0.0547945205479452,
        userIdv: 0,
      },
      vehicleInfo: {
        IDV: 0,
        RTOCode: "MH01",
        name: "Hero MotoCorp",
        previousClaim: "false",
        previousPolicyInvoiceCoverStatus: false,
        previousPolicyZeroDepStatus: false,
        regYear: "2015",
        displayVehicle: "Hero MotoCorp Duet VX",
        registrationPlace: "MH-01 Mumbai Tardeo",
        variantId: "BikeVarientId-824",
        PreviousPolicyExpiryDate: "31/3/2020",
        previousPolicyExpired: "N",
        city: "MUMBAI",
        isCostal: "N",
        isEarthQuakeArea: "Y",
        state: "Maharashtra",
        dateOfRegistration: "01/07/2015",
        PreviousPolicyStartDate: "01/04/2019",
        idvOption: 1,
      },
      QUOTE_ID: "BIKEQUOTE00141337",
      UNIQUE_QUOTE_ID_ENCRYPTED: "hFN__gQby3bVZMqwqw1s2qwqwIaCZobim__70XS7",
      contactInfo: {
        messageId: "",
        termsCondition: true,
        firstName: "Kuldeep",
        mobileNumber: "8578999999",
        createLeadStatus: false,
      },
      requestSource: "web",
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

//to generate bike proposal after 7 forms
export function generateBikeproposal(
  values,
  props,
  transactionName,
  singlerocord
) {
  localStorage.setItem("insuranceCompany", singlerocord?.insuranceCompany);
  let registrationNumber = values?.registeredRto;
  let dateOfBirth = convertDate(values?.kycNomineDob, 3);
  let vehicleLoanType = "";
  let financeInstitutionCode = values?.financierID;
  let financeInstitution = values?.financierName;
  let GSTIN = values.ownerGstin;
  let registrationValidityDate = "";
  if (
    singlerocord?.insuranceCompany === "Reliance General Insurance Co. Ltd" &&
    transactionName?.insuranceFor === "new"
  ) {
    registrationNumber = "NEW";
  }

  if (singlerocord?.insuranceCompany === "New India Assurance") {
    dateOfBirth = convertDate(values?.kycNomineDob, 1);
    vehicleLoanType = "Hypothecation";

    if (transactionName?.insuranceFor === "new") {
      let newDate = parseInt(transactionName.registrationYear) + 15;
      registrationValidityDate = "01/01/" + newDate;
    } else if (transactionName?.insuranceFor === "renew") {
      let newDate = new Date(transactionName.registrationYear);
      newDate.setFullYear(newDate.getFullYear() + 15);

      let day = newDate.getDate().toString().padStart(2, "0");
      let month = (newDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
      let year = newDate.getFullYear();

      registrationValidityDate = `${day}/${month}/${year}`;
    }
  }
  if (
    singlerocord?.insuranceCompany === "Reliance General Insurance Co. Ltd" ||
    singlerocord?.insuranceCompany === "GO DIGIT General Insurance CO. LTD"
  ) {
    vehicleLoanType = "Hypothecation";
  }
  if (values?.vehicalPurchaseLoan === "no") {
    vehicleLoanType = "NA";
  }
  if (
    values?.vehicalPurchaseLoan === "no" &&
    singlerocord?.insuranceCompany === "ICICI Lombard General Insurance Co. Ltd"
  ) {
    vehicleLoanType = "NA";
    financeInstitution = "NA";
    financeInstitutionCode = "NA";
  }

  if (
    singlerocord?.insuranceCompany ===
    "ICICI Lombard General Insurance Co. Ltd" &&
    !GSTIN
  ) {
    GSTIN = undefined;
  }

  localStorage.setItem("type", transactionName?.formtype);

  const data = {
    header: {
      transactionName:
        transactionName?.formtype === "bike"
          ? "submitBikeProposal"
          : "carProposal",
      messageId: null,
      deviceId: "ABCD12345",
    },
    body: {
      nominationDetails: {
        nomPersonAge: gateage(values?.nomineDob),
        nomFirstName: values?.nomineFirstName,
        nomLastName: values?.nomineLastName,
        nomDateOfBirth: convertDate(values?.nomineDob, 3),
        nominationRelation: values?.nomineRelation,
        nominationRelationId: 1,
      },
      vehicleDetails: {
        purchasedLoan: values?.vehicalPurchaseLoan,
        vehicleLoanType: vehicleLoanType,
        financeInstitutionCode: values?.financierID,
        financeInstitution: values?.financierName,
        engineNumber: values?.engineNumber,
        isVehicleAddressSameAsCommun: values?.isRegisteredAddressSame,
        registrationAddress: {
          regArea: values?.ownerAddress,
          regPincode: values?.ownerPincode,
          regCity: values?.ownerCity,
          regDisplayArea: values?.ownerAddress,
          regDistrict: values?.ownerLocation,
          regDisplayField: values?.ownerAddress,
          regState: values?.ownerState,
          regDoorNo: values?.ownerFlatNumber,
        },
        chassisNumber: values?.chassisNumber,
        registrationNumber: registrationNumber,
        manufacturingYear: values?.vehicalManufaDate.split("-")?.[0],
      },
      proposerDetails: {
        lastName: values?.ownerLastName,
        middleName: values?.ownerMiddleName,
        gender: values?.kycNomineGender,
        mobileNumber: values?.ownerPhoneNumber,
        maritalStatus: values?.ownerMaritialStus,
        dateOfBirth: dateOfBirth,
        emailId: values?.ownerEmail,
        panNumber: values?.kycNominePanNumber,
        personAge: gateage(values?.kycNomineDob),
        firstName: values?.ownerFirstName,
        GSTIN: GSTIN,
        communicationAddress: {
          comArea: values?.ownerAddress,
          comPincode: values?.ownerPincode,
          comCity: values?.ownerCity,
          comDisplayArea: values?.ownerAddress,
          comDistrict: values?.ownerLocation,
          comDisplayField: values?.ownerAddress,
          comState: values?.ownerState,
          comDoorNo: values?.ownerFlatNumber,
        },
        salutation: values?.ownerPrefix,
        ckycNo: values?.kycFailedData,
      },
      source: "agency",
      requestSource: "agency",
      appointeeDetails: {},
      insuranceDetails: {
        policyNumber: values?.policyNumber,
        insuranceType: props?.carbikeformikValues?.insuranceFor,
        ncb: Number(
          props?.quotesPageFormikData?.noClaimBonus
            ? props?.quotesPageFormikData?.noClaimBonus
            : props?.carbikeformikValues?.noClaimBonus
        ),
        insurerName: values?.policyInsuranceId,
        insurerId:
          singlerocord?.insuranceCompany === "New India Assurance"
            ? 25
            : values?.policyInsuranceName,
        prevPolicyType: "Comprehensive Policy",
        TPPolicyNumber: values?.tpPolicyNumber,
        TPPolicyInsurer: values?.tpPolicyInsuranceName,
      },
      carrierId: props?.singleQuotesData?.carrierId,
      productId: props?.singleQuotesData?.productId,
      QUOTE_ID: props?.createquotesresult[0]?.QUOTE_ID
        ? props?.createquotesresult[0]?.QUOTE_ID
        : props.PolicyDates?.QUOTE_ID,
      businessLineId: transactionName?.formtype === "bike" ? 2 : 3,
      campaign_id: "97c0ef04-fcac-6b03-aa58-5c0512ad1884",
      registrationValidityDate: registrationValidityDate,
      // alternatePolicyExpDateFuture: values.alternatePolicyExpDateFuture,
      // existingPAPolicyNo: values.existingPAPolicyNo,
      // existingPASumInsured: values.existingPASumInsured,
      // insurerName: values.insurerName,
      // validLicense: values.validLicense,
      // AlternatePolicyExpiryDate: values?.alternatePolicyExpDateFuture,
      // ExistingPAPolicyNo: values?.existingPAPolicyNo,
      // ExistingPASumInsured: values?.existingPASumInsured,
      // InsurerName: values?.insurerName,
      // ValidLicense: !values?.validLicense,
    },
  };

  // if (singlerocord?.insuranceCompany === "Future Generali India Insurance Co. Ltd") 
  // {
  //    data.body.futureProposalId = sessionStorage.getItem('futureProposal_ID') ?? '';
  // }

  if (singlerocord?.insuranceCompany === "Future Generali India Insurance Co. Ltd") {
    const futureProposalId = sessionStorage.getItem('futureProposal_ID');
    if (futureProposalId) {
      data.body.futureProposalId = futureProposalId;
    } else {
      console.log("No futureProposal_ID found in sessionStorage");
    }
  }


  const data1 = {
    header: {
      transactionName:
        transactionName?.formtype === "bike"
          ? "submitBikeProposal"
          : "carProposal",
      messageId: null,
      deviceId: "ABCD12345",
    },
    body: {
      nominationDetails: {
        nomPersonAge: gateage(values?.nomineDob),
        nomFullName: values?.nomineFirstName + " " + values?.nomineLastName,
        nomFirstName: values?.nomineFirstName,
        nomLastName: values?.nomineLastName,
        nomDateOfBirth: convertDate(values?.nomineDob, 3),
        nominationRelation: values?.nomineRelation,
        nominationRelationId: 1,
      },
      vehicleDetails: {
        purchasedLoan: values?.vehicalPurchaseLoan,
        vehicleLoanType: vehicleLoanType,
        financeInstitutionCode:
          values?.financierID === "" ? "NA" : values?.financierID,
        financeInstitution:
          values?.financierName === "" ? "NA" : values?.financierName,
        engineNumber: values?.engineNumber,
        isVehicleAddressSameAsCommun: values?.isRegisteredAddressSame,
        registrationAddress: {
          regArea: values?.ownerAddress,
          regPincode: values?.ownerPincode,
          regCity: values?.ownerCity,
          regDisplayArea: values?.ownerAddress,
          regDistrict: values?.ownerLocation,
          regDisplayField: values?.ownerAddress,
          regState: values?.ownerState,
          regDoorNo: values?.ownerFlatNumber,
        },
        chassisNumber: values?.chassisNumber,
        registrationNumber: registrationNumber,
        manufacturingYear: values?.vehicalManufaDate.split("-")?.[0],
      },
      proposerDetails: {
        lastName: values?.ownerLastName,
        middleName: values?.ownerMiddleName,
        gender: values?.kycNomineGender,
        mobileNumber: values?.ownerPhoneNumber,
        maritalStatus: values?.ownerMaritialStus,
        dateOfBirth: dateOfBirth,
        emailId: values?.ownerEmail,
        panNumber: values?.kycNominePanNumber,
        personAge: gateage(values?.kycNomineDob),
        firstName: values?.ownerFirstName,
        GSTIN: GSTIN,
        communicationAddress: {
          comArea: values?.ownerAddress,
          comPincode: values?.ownerPincode,
          comCity: values?.ownerCity,
          comDisplayArea: values?.ownerAddress,
          comDistrict: values?.ownerLocation,
          comDisplayField: values?.ownerAddress,
          comState: values?.ownerState,
          comDoorNo: values?.ownerFlatNumber,
        },
        salutation: values?.ownerPrefix,
        ckycNo: values?.kycFailedData,
        ilKycRefNo: values?.ilKycRefNo || "",
      },
      source: "agency",
      requestSource: "agency",
      appointeeDetails: {},
      insuranceDetails: {
        policyNumber: values?.policyNumber,
        insuranceType: props?.carbikeformikValues?.insuranceFor,
        ncb: Number(
          props?.quotesPageFormikData?.noClaimBonus
            ? props?.quotesPageFormikData?.noClaimBonus
            : props?.carbikeformikValues?.noClaimBonus
        ),
        insurerName: values?.policyInsuranceId,
        insurerId:
          singlerocord?.insuranceCompany === "New India Assurance"
            ? 25
            : values?.policyInsuranceName,
        prevPolicyType: "Comprehensive Policy",
        TPPolicyNumber: values?.tpPolicyNumber,
        TPPolicyInsurer: values?.tpPolicyInsuranceName,
      },
      carrierId: props?.singleQuotesData?.carrierId,
      productId: props?.singleQuotesData?.productId,
      QUOTE_ID: props?.createquotesresult[0]?.QUOTE_ID
        ? props?.createquotesresult[0]?.QUOTE_ID
        : props.PolicyDates?.QUOTE_ID,
      businessLineId: transactionName?.formtype === "bike" ? 2 : 3,
      campaign_id: "97c0ef04-fcac-6b03-aa58-5c0512ad1884",
      registrationValidityDate: registrationValidityDate,
    },
  };

  const data2 = {
    header: {
      transactionName:
        transactionName?.formtype === "bike"
          ? "submitBikeProposal"
          : "carProposal",
      messageId: null,
      deviceId: "ABCD12345",
    },
    body: {
      vehicleDetails: {
        purchasedLoan: values?.vehicalPurchaseLoan,
        vehicleLoanType: vehicleLoanType,
        financeInstitutionCode:
          values?.financierID === "" ? "NA" : values?.financierID,
        financeInstitution:
          values?.financierName === "" ? "NA" : values?.financierName,
        engineNumber: values?.engineNumber,
        isVehicleAddressSameAsCommun: values?.isRegisteredAddressSame,
        registrationAddress: {
          regArea: values?.ownerAddress,
          regPincode: values?.ownerPincode,
          regCity: values?.ownerCity,
          regDisplayArea: values?.ownerAddress,
          regDistrict: values?.ownerLocation,
          regDisplayField: values?.ownerAddress,
          regState: values?.ownerState,
          regDoorNo: values?.ownerFlatNumber,
        },
        chassisNumber: values?.chassisNumber,
        registrationNumber: registrationNumber,
        manufacturingYear: values?.vehicalManufaDate.split("-")?.[0],
      },
      proposerDetails: {
        lastName: values?.ownerLastName,
        middleName: values?.ownerMiddleName,
        gender: values?.kycNomineGender,
        mobileNumber: values?.ownerPhoneNumber,
        maritalStatus: values?.ownerMaritialStus,
        dateOfBirth: dateOfBirth,
        emailId: values?.ownerEmail,
        panNumber: values?.kycNominePanNumber,
        personAge: gateage(values?.kycNomineDob),
        firstName: values?.ownerFirstName,
        GSTIN: GSTIN,
        communicationAddress: {
          comArea: values?.ownerAddress,
          comPincode: values?.ownerPincode,
          comCity: values?.ownerCity,
          comDisplayArea: values?.ownerAddress,
          comDistrict: values?.ownerLocation,
          comDisplayField: values?.ownerAddress,
          comState: values?.ownerState,
          comDoorNo: values?.ownerFlatNumber,
        },
        salutation: values?.ownerPrefix,
        ckycNo: values?.kycFailedData,
        ilKycRefNo: values?.ilKycRefNo || "",
      },
      source: "agency",
      requestSource: "agency",
      appointeeDetails: {},
      insuranceDetails: {
        policyNumber: values?.policyNumber,
        insuranceType: props?.carbikeformikValues?.insuranceFor,
        ncb: Number(
          props?.quotesPageFormikData?.noClaimBonus
            ? props?.quotesPageFormikData?.noClaimBonus
            : props?.carbikeformikValues?.noClaimBonus
        ),
        insurerName: values?.policyInsuranceId,
        insurerId:
          singlerocord?.insuranceCompany === "New India Assurance"
            ? 25
            : values?.policyInsuranceName,
        prevPolicyType: "Comprehensive Policy",
        TPPolicyNumber: values?.tpPolicyNumber,
        TPPolicyInsurer: values?.tpPolicyInsuranceName,
      },
      carrierId: props?.singleQuotesData?.carrierId,
      productId: props?.singleQuotesData?.productId,
      QUOTE_ID: props?.createquotesresult[0]?.QUOTE_ID
        ? props?.createquotesresult[0]?.QUOTE_ID
        : props.PolicyDates?.QUOTE_ID,
      businessLineId: transactionName?.formtype === "bike" ? 2 : 3,
      campaign_id: "97c0ef04-fcac-6b03-aa58-5c0512ad1884",
      registrationValidityDate: registrationValidityDate,
    },
  };

  if (sessionStorage.getItem("id")) {
    data.body.proposerDetails["agentId"] = sessionStorage.getItem("id");
    data1.body.proposerDetails["agentId"] = sessionStorage.getItem("id");
  }
  if (sessionStorage.getItem("agencyId")) {
    data.body.proposerDetails["agencyId"] = sessionStorage.getItem("agencyId");
    data1.body.proposerDetails["agencyId"] = sessionStorage.getItem("agencyId");
  }
  if (sessionStorage.getItem("branchId")) {
    data.body.proposerDetails["branchId"] = sessionStorage.getItem("branchId");
    data1.body.proposerDetails["branchId"] = sessionStorage.getItem("branchId");
  }

  if (
    localStorage.getItem("dealId") &&
    localStorage.getItem("dealId") !== null &&
    localStorage.getItem("dealId") !== undefined
  ) {
    data.body.proposerDetails["dealId"] = localStorage.getItem("dealId");
    data1.body.proposerDetails["dealId"] = localStorage.getItem("dealId");
  }

  if (
    singlerocord?.insuranceCompany === "ICICI Lombard General Insurance Co. Ltd"
  ) {
    if (
      values?.nomineFirstName !== null &&
      values?.nomineFirstName !== undefined &&
      values?.nomineFirstName !== ""
    )
      return axios.post(projectBaseurl + getRtodetails, data1);
    else {
      return axios.post(projectBaseurl + getRtodetails, data2);
    }
  } else {
    return axios.post(projectBaseurl + getRtodetails, data);
  }
}

//payment API for care - integrate it

//to get payment url
export function paymentApi(values) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "paymentService",
    },

    body: values,
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

// main api - to download policy
export function downloadPolicy(mobileNumber) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "transDataReader",
    },
    body: {
      docId: `PolicyDetails-${mobileNumber ? mobileNumber : localStorage.getItem("ownerPhoneNumber")
        }`,
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// create API for download policy - old working 
export function policyDownloadRepository() {
  const id = sessionStorage.getItem("id")
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "profileData"
    },
    body: {
      agentId: id,
      operationType: "generatePolicy",
      requestSource: "web"
    }
  }
  console.log("data", data);
  return axios.post(projectBaseurl + policyDownloadRepositoryUrl, data);
}


//createCarPolicy-UAT
export function createCarPolicyDownload(apPreferId, proposalId, amount, transactionNo) {
  const data = {
    header: {
      transactionName: "createCarPolicy",
      messageId: "",
      deviceId: "ABCD12345"
    },
    body: {
      transactionStausInfo: {
        apPreferId: apPreferId,
        transactionStatusCode: 1,
        proposalId: proposalId,
        Amount: amount,
        transactionNo: transactionNo,
        source: "agency"
      },
      productId: 18,
      carrierId: 29,
      businessLineId: 3
    }
  };
  return axios.post(projectBaseurl + createCarPolicyURL, data);
}

//UAT-bike
export function createBikePolicyDownload(apPreferId, proposalId, amount, transactionNo) {
  const data = {
    header: {
      transactionName: "createBikePolicy",
      messageId: "",
      deviceId: "ABCD12345"
    },
    body: {
      transactionStausInfo: {
        apPreferId: apPreferId,
        transactionStatusCode: 1,
        proposalId: proposalId,
        Amount: amount,
        transactionNo: transactionNo,
        source: "agency"
      },
      productId: 17,
      carrierId: 29,
      businessLineId: 2
    }
  };
  return axios.post(projectBaseurl + createBikePolicyURL, data);
}



// policy status - UW
export function carePolicyStatusService(proposalId) {
  console.log("policy status values");

  // Fetch proposalId from localStorage
  const storedProposalId = localStorage.getItem("proposalId");
  console.log("Localstorage proposalId:", storedProposalId);

  const data = {
    header: {
      messageId: "null",
      deviceId: "ABCD12345",
      transactionName: "healthPolicyStatus",
    },
    body: {
      carrierId: 67,
      planId: 1,
      policyType: "new",
      businessLineId: 4,
      // proposalId:localStorage.getItem("proposalId")
      proposalId: proposalId,
    },
  };

  console.log("policy status data", data);
  return axios.post(projectBaseurl + policyStatus, data);
}


export function kycVeryfy(values) {
  const data = {
    URL: process.env.REACT_APP_RELIANCE_KYC_URL,
    request: {
      PAN: values.kycNominePanNumber,
      DOB: convertDate(values.kycNomineDob, 3),
      CKYC: "",
      MOBILE: "",
      PINCODE: "",
      BIRTHYEAR: "",
      ReturnURL: "test",
      UNIQUEID: Math.floor(100000 + Math.random() * 900000),
      CIN: "",
      VOTERID: "",
      "DL_No ": "",
      PASSPORT: "",
      AADHAAR_NO: "",
      FULLNAME: "",
      GENDER: "",
    },
    headers: {
      "Subscription-Key": process.env.REACT_APP_RELIANCE_KYC_URL_Subscription,
      "Content-Type": "application/json",
    },
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function getFullAddress(pincode) {
  return axios.get(addressUrl + pincode);
}

export function getMakeModel(type) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: "",
      documentType: type,
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

export function vahanDataCounch(id, type) {
  const data = {
    // documentId: `${type === "2WN" ? "BikeVarientId-" : "CarVarientId-"}` + id,
    documentId: `${bikeData.includes(type) ? "BikeVarientId-" : "CarVarientId-"}` + id,

  };
  console.log("dattata", data)
  return axios.post(projectBaseurl + kycBaseurl, data);
}

// future kyc api to create token
export function getTokenForFutureApi() {
  const data = {
    URL: "https://fgnluat.fggeneral.in/NLCKYC/API/CKYC/GenerateToken",
    request: {},
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function getKycStatusFuture(proposalID) {
  const data = {
    "URL": "https://fglpg001.futuregenerali.in/NLCKYC/API/CKYC/GetCKYCStatus",
    "carrierId": 37,
    "request": {
      "proposal_id": proposalID
    }
  }
  return axios.post(projectBaseurl + kycBaseurl, data);
}
// future kyc api to get kyc details
export function getKycFutureApi(key, values) {
  const data = {
    URL: "https://fgnluat.fggeneral.in/NLCKYC/API/CKYC/CreateCKYC",
    request: {
      req_id: key,
      customer_type: "I",
      id_type: "PAN",
      id_num: values?.kycNominePanNumber,
      full_name: values?.kycNomineFullName,
      gender: values?.kycNomineGender.charAt(0).toUpperCase(),
      dob: convertDate(values?.kycNomineDob, 3),
      system_name: "Webagg",
    },
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

// get state
export function getState() {
  const data = {
    documetType: "State",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
// get state
export function getCity(state) {
  const data = {
    documetType: "State",
    state: state,
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

//api for pincode-old
export function getPincodeData(values) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: values,
      documentType: "Pincode",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

export function loanFinancer() {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue: "",
      documentType: "LoanFinancer",
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
export function loanFinancerHDFC(searchString) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      searchValue:
        searchString === null || searchString === undefined ? "" : searchString,
      documentType: "LoanFinancerInfo",
      campaign_id: "316903a4-ff2e-a04d-8f16-5cbae50328ed",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// to get list of all riders
export function getpolicyProviderName(name) {
  // const data = {
  //     "documentType": "InsuranceMapping",
  //     "insurerName": "",
  //     "InsurerCode": ""
  // }
  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": 47,
    "New India Assurance": 25,
    "Future Generali India Insurance Co. Ltd": 37,
    "GO DIGIT General Insurance CO. LTD": 61,
    "ICICI Lombard General Insurance Co. Ltd": 29,
    "HDFC ERGO General Insurance Company": 28,
    "Bajaj Allianz General Insurance Co. Ltd": 52,
  };

  const data = {
    documentType: "Carrier",
    selectedCarrier: imgArrayForCard?.[name],
  };
  return axios.post(projectBaseurl + getpolicyName, data);
}

// to get list of all riders
export function shareQuotes(quotesID = "BIKEQUOTE00175904") {
  const data = {
    header: {
      transactionName: "quoteDataReader",
      messageId: "",
      deviceId: "ABCD12345",
    },
    body: {
      docId: quotesID,
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

export function mobileNumber(action, number, enteredOTP) {
  const data = {
    action: action,
    mobileNumber: number,
    enteredOTP: action === "sendOTP" ? null : enteredOTP,
  };
  return axios.post(projectBaseurl + mobileNumberurl, data);
}

export function downloadPolicyDoc(mobileNumber, pKey, values) {
  const data = {
    header: {
      transactionName: "policyDocDownloadService",
      messageId: "",
      deviceId: "ABCD12345",
    },
    body: {
      mobile: localStorage.getItem("ownerPhoneNumber"),
      pKey: pKey,
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

// to generate token for icici kyc
export function iciciKycToken() {
  const data = {
    request: {
      password: "q3gnc4My5rVRHY",
      grant_type: "password",
      scope: "esb-kyc",
      client_secret: "Egudh6H8bZX9wYqc7aLE5dg8",
      client_id: "NavneetIns",
      username: "NavneetIns",
    },
    headers: {
      CamelHttpMethod: "POST",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    URL: "https://app9.icicilombard.com/cerberus/connect/token",
  };

  return axios.post(projectBaseurl + kycBaseurl, data);
}

// to generate token for icici kyc
export function iciciKycVerify(token, values) {
  const data = {
    request: {
      certificate_type: "PAN",
      correlationId: "UUID",
      pan_details: {
        dob: convertDate(values?.kycNomineDob, 3),
        pan: values?.kycNominePanNumber,
      },
      pep_flag: false,
    },
    URL: "https://online.icicilombard.com/ilservices/customer/v1/kyc/initiate",
    carrierId: 29,
    headers: {
      Authorization: "Bearer " + token,
      CamelHttpMethod: "POST",
      "Content-Type": "application/json",
    },
  };

  const data2 = {
    request: {
      certificate_type: "AADHAAR",
      correlationId: "UUID",
      aadhaar_details: {
        aadhaarNumber: values?.kycNomineAadharNumber,
        aadhaarName: values?.kycNomineFullName,
        dob: convertDate(values?.kycNomineDob, 3),
        gender: values?.kycNomineGender,
      },
      pep_flag: false,
    },
    URL: "https://online.insurancearticlez.com/ilservices/customer/v1/kyc/initiate",
    carrierId: 29,
    headers: {
      Authorization: "Bearer " + token,
      CamelHttpMethod: "POST",
      "Content-Type": "application/json",
    },
  };
  if (values?.kycType === "pan")
    return axios.post(projectBaseurl + kycBaseurl, data);
  else if (values?.kycType === "aadhar")
    return axios.post(projectBaseurl + kycBaseurl, data2);
  else return axios.post(projectBaseurl + kycBaseurl, data);
}

export function iciciKycDocSubmit(token, email, icicidoc) {
  let formData = new FormData();
  const phonunember = localStorage.getItem("customerphoneno");
  const mobileNumber = "91" + phonunember;
  // const phonunember1 = localStorage.getItem("ownerPhoneNumber1");
  // const mobileNumber1 = "91" + phonunember1;
  if (phonunember) formData.append("mobileNumber", mobileNumber);
  // if (phonunember1) formData.append("mobileNumber1", mobileNumber1);

  formData.append("email", email);
  formData.append("isPoaPoiSame", false);
  formData.append("poiCertificateType", icicidoc?.poitype);
  formData.append("poiDocument", icicidoc?.poiFile);
  formData.append("poaCertificateType", icicidoc?.poatype);
  formData.append("poaDocument", icicidoc?.poaFile);
  //formData.append("CorrelationId", "fda0df66-beaf-4365-9197-1bf4f7cec720");
  formData.append("customerType", "I");
  const data = {
    request: formData,
    URL: "https://online.icicilombard.com/ilservices/customer/v1/kyc/ovd",
    headers: {
      Authorization: "Bearer " + token,
      CamelHttpMethod: "POST",
      "Content-Type": "application/json",
    },
  };
  const config = {
    headers: { Authorization: "Bearer " + token },
  };
  // return axios.post(projectBaseurl + kycBaseurl, data,)
  return axios.post(
    process.env.REACT_APP_AGENCY_URL + "/iciciKycOvd",
    formData
  );
}

//to get proposal list
export function getProposalList(mob) {
  const id = sessionStorage.getItem("id")
  console.log("IDDDD", id)
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      agencyId: id,
      mobile: mob,
      documentType: "proposalInfo",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

//to get proposal list - for UW
export function getProposalListUWAgent() {
  const agentid = sessionStorage.getItem("id")
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "findAppConfig",
    },
    body: {
      agentId: agentid,
      documentType: "proposalInfo",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}

//to get proposal details
export function getProposal(values) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "transDataReader",
    },
    body: {
      docId: values,
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };

  return axios.post(projectBaseurl + getRtodetails, data);
}


//to get proposal list - for UW
// export function getProposalListUWAgent() {
//   const agentid =   sessionStorage.getItem("id")
//   const data = {
//     header: {
//       source: "web",
//       deviceId: "web",
//       browser: "Firefox V -74",
//       transactionName: "findAppConfig",
//     },
//     body: {
//       agentId :agentid,
//       documentType: "proposalInfo",
//       requestSource: "web",
//       leadSource: {
//         utm_campaign: "",
//       },
//     },
//   };

//   return axios.post(projectBaseurl + getRtodetails, data);
// }

export function vahanDataCouchbase(vehNum) {
  const data = {
    header: {
      source: "web",
      deviceId: "web",
      browser: "Firefox V -74",
      transactionName: "getvehiclertodetails",
    },
    body: {
      task: "detailedSearch",
      essentials: {
        vehicleNumber: vehNum,
        signzyID: "AKYTSZY",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}

export function listsForAgent(dataReq) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "profileData",
    },
    body: {
      agentId: dataReq?.agentID ? dataReq?.agentID : "",
      mobile: dataReq?.mobileNo ? dataReq?.mobileNo : "",
      policyIssueDate: dataReq?.date ? dataReq?.date : "",
      phoneNumber: "",
      operationType: dataReq?.operationType ? dataReq?.operationType : "",
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
// CRM api start
export function crmLogin() {
  const data = {
    username: "navnit_api",
    password: "navnit@api123",
  };
  return axios.post("https://navnit.skycrm.app/get_token", data);
}

export function crmGetLoginUser(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.get("https://navnit.skycrm.app/getloginuser", config);
}

export function crmCreateLead(token, data) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // const data = {
  //   customer_name: "Abhishek",
  //   mobile_number: "9850011010",
  //   caller_name: "NIB048",
  //   email_id: "ab@gmail.com",
  //   residential_address: "wadgaon BK",
  //   date_of_birth: "27/07/1994",
  //   pan_number: "ASGPC1920R",
  //   Policy_Exp_date: "29/06/2024",//pass previous policyExp//pratik need to check
  //   policy_no: "D153506700",//pass previous policy no//pratik need to check
  //   insurance_company: "GO DIGIT General Insurance CO. LTD",
  //   state: "MAHARASHTRA",
  //   pincode: "411041",
  //   RTO_no: "MH01AA4489"
  // }
  return axios.post(
    "https://navnit.skycrm.app/createleadinmotor",
    data,
    config
  );
}

export function crmLeadUpdate(token, data) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.patch(
    "https://navnit.skycrm.app/createleadinmotor",
    data,
    config
  );
}

export function crmStageUpdateApi(token, data) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(
    "https://navnit.skycrm.app/additionaltwo_update",
    data,
    config
  );
}

export function newIndVahanApi(vehicalNumber) {
  const data = {
    request: {
      PartnerID: "POLICY365",
      VehicleNumber: vehicalNumber,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic VUFUUkNWOk5pYUAxMjM0",
    },
    URL: "https://uatb2bug.newindia.co.in/b2b/rcVerification",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function godigitTokenApi() {
  const data = {
    request: {
      username: "35327650",
      password: "Digit@123$",
    },
    headers: {
      "Content-Type": "application/json",
      Cookie:
        "TS017fdda2=0138ecebf9cbb82f4a85e20d657b10f88a73d00f5597bd5c1232b03e89050a0b945a17683a80b32f806330645eb0e3a5726e98638e",
    },
    URL: "https://preprod-oneapi.godigit.com/OneAPI/digit/generateAuthKey",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}

export function godigitBreakInstaus(token, polNumber) {
  const data = {
    request: {
      queryParam: {
        policyNumber: polNumber,
      },
    },
    headers: {
      "Content-Type": "application/json",
      integrationId: "22795-0100",
      Authorization: `Bearer ${token}`,
      Cookie:
        "TS017fdda2=0138ecebf9cbb82f4a85e20d657b10f88a73d00f5597bd5c1232b03e89050a0b945a17683a80b32f806330645eb0e3a5726e98638e",
    },
    URL: "https://preprod-oneapi.godigit.com/OneAPI/v1/executor",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
//to get My Profile details
export function getMyProfile(mobileNumber) {
  const data = {
    header: {
      messageId: "P365754",
      source: "web",
      deviceId: "ABCD12345",
      browser: "Firefox V - 71",
      transactionName: "transDataReader",
    },
    body: {
      docId: `UserProfile-${mobileNumber}`,
      requestSource: "web",
      leadSource: {
        utm_campaign: "",
      },
    },
  };
  return axios.post(projectBaseurl + getRtodetails, data);
}
//update Profile details
export function updateMyProfileApi(values) {
  const data = {
    action: "updateUserProfile",
    firstName: values?.firstName,
    lastName: values?.lastName,
    mobileNumber: values?.mobileNumber,
    gender: values?.gender,
    emailId: values?.EmailId,
    maritalStatus: values?.maritalStatus,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(projectBaseurl + mobileNumberurl, data, config);
}
export function godigitPayment(token, id, proposalNo, type) {
  const data = {
    request: {
      successReturnUrl:
        projectBaseurl +
        `/cxf/paymentresponse/service/success/obengen/${type === 3 ? "car" : "bike"
        }?ProposalNo=` +
        proposalNo,
      applicationId: id,
      cancelReturnUrl:
        projectBaseurl +
        `/cxf/paymentresponse/service/failure/obengen/${type === 3 ? "car" : "bike"
        }?ProposalNo=` +
        proposalNo,
      paymentMode: "EB",
    },
    headers: {
      "Content-Type": "application/json",
      integrationId: "23216-0100",
      Authorization: `Bearer ${token}`,
      Cookie:
        "TS017fdda2=0138ecebf9eb49b41bfc9122f3e475ec4367647b7db7fbc614603ffae5146fd1a7fab5a9fb9be7628089b960e25739a71372cd4856; TS017fdda2=0138ecebf996485ac152f34a2fcbd10c3abfed242aed6258537fedac9ff3a21b82280ae8e29142ba5cf11eefeb025c976df69edaf8",
    },
    URL: "https://preprod-oneapi.godigit.com/OneAPI/v1/executor",
  };
  return axios.post(projectBaseurl + kycBaseurl, data);
}
