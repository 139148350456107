import React, { useEffect, useState, useMemo } from 'react';
import { Button, Card, Col, Container, Form, Row, Collapse } from 'react-bootstrap';
import UseFormContext from '../../../context/UseFormContext';
import { useLocation, useParams } from 'react-router-dom';
import InputFieldText from '../../commonModules/InputFieldText';
import InputFieldDropdown from '../../commonModules/InputFieldDropdown';
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";
import { useFormik } from 'formik';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import reliance from "../../../images/reliance.png";
import Star from "../../../images/star.svg";
import Best from "../../../images/best-value.svg";
import Lowest from "../../../images/lowest.svg";
import careLogo from "../../../images/careLogo.jpg"
import futureGenrali from "../../../images/futureGenrali.png";
import ICICI from "../../../images/ICICI-insurance.png"
import Godigit from "../../../images/Godigit.png";
import NewIndia from "../../../images/new-india.png"
import SVG from "react-inlinesvg";
import { FullStar } from "../../pages/Rating/FullStar";
import { HalfStar } from "../../pages/Rating/HalfStar";
import { ZeroStar } from "../../pages/Rating/ZeroStar";
import * as api from "../../../API/authCurd";
import Arrow from "../../../images/down-arrow.svg";
import { date } from 'yup';
import InputFieldRadio from '../../commonModules/InputFieldRadio';
import { Margin } from 'react-to-pdf';
import "../../style/CSS/HealthQuote.css"
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import VerticallyCenteredModal from "../../commonModules/Popups/VerticallyCenteredModal";
import "./CSS/HealthQuote.css"
import { useHistory } from 'react-router-dom';
import { MdVisibility } from 'react-icons/md';
import SignIn from "../../pages/SignIn";
// import { createHealthQuoteApi } from '../../../API/authCurd';


const HealthQuote = (props) => {
  const formContext = UseFormContext();
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [quotesResultsArray, setquotesResultsArray] = useState([]);
  const [loaderForQuotes, setloaderForQuotes] = useState(false);
  const [count, setcount] = useState(0);
  const [shareQuotesData, setshareQuotesData] = useState();
  const [previousPolicyTerm, setPreviousPolicyTerm] = useState('');
  const [memberDetailsOpen, setMemberDetailsOpen] = useState(true);
  const [insuranceForOpen, setInsuranceForOpen] = useState(true);
  const [riderStatus, setriderStatus] = useState(false);
  const [riders, setRiders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRiders, setSelectedRiders] = useState([]);
  const [totalPremium, setTotalPremium] = useState(0);
  const [selectedGlobalCoverageOptions, setSelectedGlobalCoverageOptions] = useState("");
  const [isGlobalCoverageChecked, setIsGlobalCoverageChecked] = useState(false);
  const [ownedByPopup, setownedByPopup] = useState(false);
 

  const dealId = localStorage.getItem("dealId");
  console.log("dealId", dealId)
  var loadercount = 0;
  const imgArrayForCard = {
    "Care Health Insurance": careLogo,
  }
  const sumInsuredValues = [
    { label: "Select", value: "" },
    { label: "3 lakhs", value: "3" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
    { label: "3 crores", value: "300" },
    { label: "6 crores", value: "600" },
  ];

  const policyTermValues = [
    { label: '1 Year', value: "1" },
    { label: '2 Year', value: "2" },
    { label: '3 Year', value: "3" },
  ];

  const genderValues = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
  ]

  const initialData = {
    gender: location.state?.values?.gender ?? '',
    existanceIllness: location.state?.values?.existanceIllness ?? '',
    sumInsured: location.state?.values?.sumInsured ?? '',
    pincode: location.state?.values?.pincode ?? '',
    firstName: location.state?.values?.firstName ?? '',
    lastName: location.state?.values?.lastName ?? '',
    email: location.state?.values?.email ?? '',
    phoneNumber: location.state?.values?.phoneNumber ?? '',
    illnesses: location.state?.values?.illnesses
      ? {
        diabetes: location.state?.values?.illnesses.diabetes,
        bloodPressure: location.state?.values?.illnesses.bloodPressure,
        thyroid: location.state?.values?.illnesses.thyroid,
        asthma: location.state?.values?.illnesses.asthma,
        anySurgery: location.state?.values?.illnesses.anySurgery,
        otherDiseases: location.state?.values?.illnesses.otherDiseases,
      } : {
        diabetes: '',
        bloodPressure: '',
        thyroid: '',
        asthma: '',
        anySurgery: '',
        otherDiseases: '',
      },
    sumInsured: location.state?.values?.sumInsured ?? '',
    healthInsurefor: location.state?.values?.healthInsurefor ? JSON.parse(location.state?.values?.healthInsurefor) : [],
    policyTerm: location.state?.values?.policyTerm || '1',
    // // Additional fields initialization
    // field_NCB: location.state?.values?.field_NCB ?? 'NCB Super',
    // field_35: location.state?.values?.field_35 ?? 'Air Ambulance',
    // field_43: location.state?.values?.field_43 ?? 'Reduction in PED',
    // field_AHC: location.state?.values?.field_AHC ?? 'Annual Health Check up',
    // field_34: location.state?.values?.field_34 ?? 'Room Rent Modification',
    // field_COPAY: location.state?.values?.field_COPAY ?? 'Co Payment',
    // field_SS: location.state?.values?.field_SS ?? 'Smart Select',
    // field_OPD: location.state?.values?.field_OPD ?? 'Care OPD',
    // field_CS: location.state?.values?.field_CS ?? 'Care Shield',
    ridersList: location.state?.values?.riderList ? JSON.parse(location.state?.values?.riderList) : [],

  };

  // const formik = useFormik({
  //   initialValues: initialData,
  //   onSubmit: (values) => {
  //     setIsFormDirty(false);
  //     apiToCreateHealthQuotes(values);
  //   },
  // });


  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => {
      setIsFormDirty(false);
      const requestData = { ...values, ridersList: formik.values.ridersList };
      apiToCreateHealthQuotes(requestData);
    },
  });

  console.log("################formik.values############", formik.values)

  useEffect(() => {
    const isPageRefreshed = sessionStorage.getItem("isPageRefreshed");

    if (isPageRefreshed) {
      let params = new URLSearchParams(window.location.search);
      const quotesID = params.get("quoteID");
      const shareId = sessionStorage.getItem("quoteId") || quotesID;
      console.log("QUOteID------->", shareId)

      history.replace(`/HealthPaymentFailed/${shareId}`);
    }
    sessionStorage.setItem("isPageRefreshed", "true");
    return () => {
      sessionStorage.removeItem("isPageRefreshed");
    };
  }, [history, api]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const quotesID = params.get("quoteID");
    if (quotesID) {
      // sessionStorage.setItem("quoteId", quotesID)
      formContext.setloaderStatus(true);
      setloaderForQuotes(true);
      console.log("####In if quote ID####")
      api
        .shareQuotes(quotesID)
        .then((resdata) => {
          const jsonData = JSON.parse(resdata.data);
          const data = jsonData.data
          const objectForHealth = {
            gender: data.healthQuoteRequest?.healthInfo?.healthInsurefor[0]?.gender ?? '',
            existanceIllness: data.healthQuoteRequest?.healthInfo?.existingIllness ?? '',
            sumInsured: data.healthQuoteRequest?.healthInfo?.sumInsured ?? '',
            pincode: data.healthQuoteRequest?.healthInfo?.pinCode ?? '',
            firstName: data.healthQuoteRequest?.healthInfo?.firstName ?? '',
            lastName: data.healthQuoteRequest?.healthInfo?.lastName ?? '',
            email: data.healthQuoteRequest?.healthInfo?.emailId ?? '',
            phoneNumber: data.healthQuoteRequest?.healthInfo?.mobileNumber ?? '',
            eldestMemberAge: data.healthQuoteRequest?.healthInfo?.eldestMemberAge ?? '',
            zone: data.healthQuoteRequest?.healthInfo?.zone ?? '',
            illnesses: {
              diabetes: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.diabetes ? true : false,
              bloodPressure: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.bloodPressure ? true : false,
              thyroid: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.thyroid ? true : false,
              asthma: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.asthma ? true : false,
              anySurgery: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.anySurgery ? true : false,
              otherDiseases: data.healthQuoteRequest?.healthInfo?.existingIllnessDetails?.otherDiseases ? true : false,
            },
            sumInsured: data.healthQuoteRequest?.healthInfo?.sumInsured ?? '',
            healthInsurefor: data.healthQuoteRequest?.healthInfo?.healthInsurefor ?? [],
            policyTerm: data.healthQuoteRequest?.healthInfo?.policyTerm ?? '1',
            riderList: data.healthQuoteRequest?.healthInfo?.riderList ?? [],
          };

          console.log("sharedQuote objectForHealth---->", objectForHealth)
          formik.setValues(objectForHealth)

          console.log("objectForHealthQuote", data)
          //formik.setIsFormDirty(false);
          setIsFormDirty(false)

          formContext.setSelectedHealthQuoteData(data?.healthQuoteResponse);
          formContext.setHealthQuoteFormikData(objectForHealth);
          setquotesResultsArray(data?.healthQuoteResponse)

          const businessLineId = jsonData?.data.businessLineId;
          var nestedDataVeh;
          var nestedData;
          var nestedDate;
          setTimeoutMethod()//previously commented 
        })

        .catch((err) => {
          console.log("share quotes err", err);
        })
        .finally((fin) => {
          formContext.setloaderStatus(false);
          setloaderForQuotes(false);
        });
    }
  }, []);

//handle dropdown change 
const handleDropdownChange = (rider, event) => {
  const selectedOption = event.target.value;
  let riderCode = '';  
  if (selectedOption === 'WW Excl. US and Canada') {
    riderCode = 'NEGCE4092';
  } else if (selectedOption === 'Worldwide') {
    riderCode = 'NEGCI4093';
  } else if (selectedOption === 'Not Opted') {
    riderCode = '';
  }

  setSelectedGlobalCoverageOptions(selectedOption);
    setDefaultRiders((prev) => 
      prev.map((defRider) => {
          if (defRider.name === rider.name) {
              return {
                  ...defRider,
                  value: selectedOption,
                  riderCode: riderCode
              };
          }
          return defRider;
      })
  );

  const currentRiders = Array.isArray(formik.values.ridersList) ? formik.values.ridersList : [];
  const updatedRiders = currentRiders.map(r => {
      if (r.name === rider.name) {
          return { 
              ...r, 
              value: selectedOption,
              riderCode: riderCode
          };
      }
      return r;
  });
  formik.setFieldValue('ridersList', updatedRiders);
  setSelectedRiders(updatedRiders);
};
  


  useEffect(() => {
    console.log("quotesResultsArray", quotesResultsArray);
    if (quotesResultsArray?.length > 0) {
      formContext.setquotesList(quotesResultsArray);

      const eldestMember = quotesResultsArray[0]?.healthInsurefor.reduce((max, current) => {
        return (current.age > max.age) ? current : max;
      });
      const minAge = eldestMember?.age || 0;
      const zone = quotesResultsArray[0]?.zone || "";            
      if ((zone === "zone 1" || zone === "zone 1a") && minAge <= 45) {
        if (!defaultRiders.some((rider) => rider.name === "NCB Super")) {
          setDefaultRiders((prev)=>[
            ...prev,  {
              "amount": 783.03,
              "name": "NCB Super",
              "description": "Get your sum insured increased up to 150% in 5 consecutive claim free years",
              "type": "O",
              "riderId": 2,
              "riderCode": "CAREADWITHNCB",
              "isCheckedAutomatically": true
            }
          ]);
        }
      }

      if (['200', '300', '600'].includes(formik.values.sumInsured)) {
        const globalCoverageRider = quotesResultsArray[0]?.riderList?.find(
          rider => rider.name === "Global Coverage"
        );
        
        if (globalCoverageRider && !defaultRiders.some((rider) => rider.name === "Global Coverage")) {
          setDefaultRiders((prev) => [
            ...prev,
            {
              ...globalCoverageRider,
              value: "WW Excl. US and Canada",
              riderCode: "NEGCE4092",
              isCheckedAutomatically: true
            }
          ]);
          setSelectedGlobalCoverageOptions("WW Excl. US and Canada");
          setIsGlobalCoverageChecked(true);

          const currentRiders = Array.isArray(formik.values.ridersList) ? formik.values.ridersList : [];
          if (!currentRiders.some(r => r.name === "Global Coverage")) {
            formik.setFieldValue('ridersList', [
              ...currentRiders,
              {
                ...globalCoverageRider,
                value: "WW Excl. US and Canada",
                riderCode: "NEGCI4092"
              }
            ]);
          }
        }
      }
    }
  }, [quotesResultsArray, formik.values.sumInsured]);

  useEffect(() => {
    if (id) {
      formContext.setgetquotesApiFlag(false);
    }
    if (id && formContext.healthQuoteFormikData) {
      formik.setValues({
        ...formik.initialValues,
        ...formContext.healthQuoteFormikData,
      });

     
      console.log("formContext.quotesList", formContext.quotesList)
      setquotesResultsArray(formContext.quotesList)
    }
  }, []);


  useEffect(() => {
    if (formik.dirty) {
      setIsFormDirty(true);
    }
  }, [formik?.values, formik?.dirty]);


  const handleCancel = () => {
    formik.resetForm();
    setTimeoutMethod();
  };
  const setTimeoutMethod = () => {
    setTimeout(() => {
      setIsFormDirty(false);
    }, 500);
  };

  const changeDOB = (event, memberId) => {
    formik.setFieldValue('healthInsurefor', formik.values.healthInsurefor.map((member) => {
      if (member.id === memberId) {
        member.dateOfBirth = event.target.value;
      }
      return member;
    }))
    formik.setIsFormDirty(true);
  };

  const apiResopnse = (data) => {
    const jsonData = JSON.parse(data);
    console.log("jsonData---------", jsonData)
    if (jsonData?.data?.quotes && jsonData?.data?.quotes.length) {
      setquotesResultsArray((prevdata) => { return [...prevdata, ...jsonData?.data?.quotes] })
    }
  }
  // let defaultRiders = [
  //   { name: 'Unlimited Automatic Recharge', amount: "", riderCode: "UARV1195", riderId: 1, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
  //   { name: 'Pre and Post Hospitalization', amount: 0, riderCode: "PREPHECA1150", riderId: 11, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
  //   { name: 'Unlimited E-Consultation', amount: 0, riderCode: "UECV1153", riderId: 12, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
  // ];
  const [defaultRiders, setDefaultRiders] = useState( [
    { name: 'Unlimited Automatic Recharge', amount: "", riderCode: "UARV1195", riderId: 1, type: "M", description: "Automatically restores your sum insured unlimited times in a policy year after it gets exhausted due to claims" },
    { name: 'Pre and Post Hospitalization', amount: 0, riderCode: "PREPHECA1150", riderId: 11, type: "M", description: "Covers medical expenses incurred before and after hospitalization within a specified period" },
    { name: 'Unlimited E-Consultation', amount: 0, riderCode: "UECV1153", riderId: 12, type: "M", description: "Provides unlimited access to teleconsultations with qualified doctors for medical advice." },
  ])
 

  const handleCheckboxChange = (rider, event) => {
    if (rider.name === "Global Coverage" && ['200', '300', '600'].includes(formik.values.sumInsured)) {
      return;
    }
  
    const currentRiders = Array.isArray(formik.values.ridersList) ? formik.values.ridersList : [];  
    if (event.target.checked) {
      setSelectedRiders((prevSelectedRiders) => {
        const updatedRiders = [...prevSelectedRiders, rider];
        const updatedRidersWithDefaults = [
          ...updatedRiders,
          ...currentRiders.filter((existingRider) =>
            defaultRiders.some(defaultRider => defaultRider.name === existingRider.name)
          )
        ];
        formik.setFieldValue('ridersList', updatedRidersWithDefaults);
        return updatedRidersWithDefaults;
      });
    } else {
      setSelectedRiders((prevSelectedRiders) => {
        const updatedRiders = prevSelectedRiders.filter((r) => r.riderId !== rider.riderId);
        formik.setFieldValue('ridersList', updatedRiders);
        return updatedRiders;
      });
    }
  
    if (rider.name === "Global Coverage" && ["200", "300", "600"].includes(formik.values.sumInsured)) {
      setIsGlobalCoverageChecked(true);
      setSelectedGlobalCoverageOptions("Worldwide");
      const updatedRiders = currentRiders.map(r => {
        if (r.name === rider.name) {
          return { 
            ...r, 
            value: "Worldwide",
            riderCode: "NEGCI4093"
          };
        }
        return r;
      });
    
      if (!currentRiders.some(r => r.name === rider.name)) {
        updatedRiders.push({ 
          ...rider, 
          value: "Worldwide",
          riderCode: "NEGCI4093"
        });
      }
    
      formik.setFieldValue('ridersList', updatedRiders);
      setSelectedRiders(updatedRiders);
    }
  };
  
  const handleSave = () => {
    const updatedRidersList = [
      ...formik.values.ridersList,
      ...defaultRiders.filter((defaultRider) =>
        !formik.values.ridersList.some(rider => rider.name === defaultRider.name)
      )
    ];

    const uniqueRidersList = [
      ...new Map(updatedRidersList.map(rider => [rider.name, rider])).values()
    ];
    formik.setFieldValue('ridersList', uniqueRidersList);
    setshareQuotesData(formik?.values);
    formContext.setquotesPageFormikData(formik?.values);
    formik.handleSubmit();
    setIsFormDirty(false);
    recalculatePremium(quotesResultsArray);
  };

  const recalculatePremium = (quotesResultsArray) => {
    console.log("selected riders", selectedRiders);
    const updatedQuotes = quotesResultsArray.map((quotes) => {
      console.log("Quotesss", quotes);
      const premium = Number(quotes.totalPremium.replace(/,/g, ""));

      const newPermium = selectedRiders.reduce((accumulator, item) => {
        return accumulator + item.amount;
      }, premium);
      quotes.newPermium = newPermium;
      return quotes;
    });

    console.log("Updated quote", updatedQuotes);
    setquotesResultsArray(updatedQuotes);
  };


  //new
  const apiToCreateHealthQuotes = (values) => {
    const pincodeData = formContext.pincodeData;
    console.log("Pincode FormContext", pincodeData);
    formContext.setloaderStatus(true);
    setloaderForQuotes(true);

    api.createHealthQuoteApi(values)
      .then((data) => {
        console.log("printttt",data.data)
        redirectMethod(values, data);
      })
      .catch((err) => {
        console.log("Error while fetching quotes", err);
        formContext.notifyError("An error occurred while fetching data.");
        formContext.setquotesPageFormikData();
      })
      .finally(() => {
        setIsFormDirty(false);
        setloaderForQuotes(false);
      });
  };

  //old
  // const saveSelectedRiders = () => {
  //   const updatedRidersList = [
  //     ...formik.values.ridersList,
  //     ...defaultRiders.filter((defaultRider) =>
  //       !formik.values.ridersList.some(rider => rider.name === defaultRider.name)
  //     )
  //   ];
  //   // remove duplicate objects
  //   formContext.setSelectedRiders(updatedRidersList);
  //   formik.setFieldValue('ridersList', updatedRidersList); // To ensure formik's value is also updated.
  // };

  //new
  const saveSelectedRiders = () => {
    const updatedRidersList = [
      ...formik.values.ridersList,
      ...defaultRiders.filter((defaultRider) =>
        !formik.values.ridersList.some(rider => rider.name === defaultRider.name)
      )
    ];
    const uniqueRidersList = [
      ...new Map(updatedRidersList.map(rider => [rider.name, rider])).values()
    ];
    formContext.setSelectedRiders(uniqueRidersList);
    formik.setFieldValue('ridersList', uniqueRidersList); 
  };



  const backButtonPressed = () => {
    history.push(formContext.routingPath);
  };
  const getHealthQuoteResult = (singlerecord, currentIndex, actuallength) => {
    formContext.setloaderStatus(true);

    api
      .getHealthQuoteApi(
        singlerecord?.qname,
        singlerecord?.messageId,
        singlerecord?.QUOTE_ID
      )
      .then((data) => {

        apiResopnse(data, actuallength, currentIndex);
        console.log("data----->", data)
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {
        setcount((prev) => prev + 1);
        loadercount = loadercount + 1;
        setTimeout(() => {
          if (actuallength === loadercount) {
            formContext.setloaderStatus(false);
            setloaderForQuotes(false);
            // recalculatePremium(quotesResultsArray)
          }
        }, 1000);
      });
  };
  useEffect(() => {
    setcount(0);
    loadercount = 0;
    const singlerecord = formContext.createquotesresult;
    if (singlerecord?.length > 0 && formContext.getquotesApiFlag) {
      setloaderForQuotes(true);
      for (let i = 0; i < singlerecord?.length; i++) {
        getHealthQuoteResult(singlerecord[i], i, singlerecord?.length);
      }
    }
  }, [formContext.createquotesresult]);

 // to redirect user after login success
//  useEffect(() => {
//   if (
//     formContext?.loginPopupStatus === false &&
//     sessionStorage.getItem("userLoggin") 
//     // quotesResultsArray[0]?.carrierVariantDisplayName
//   ) {
//     history.push("/healthProposal");
//     formContext.setHealthQuoteFormikData(formik?.values);
//     formContext.setloaderStatus(false);
//     formContext?.loginPopupStatus(true)
//   }
// }, [formContext?.loginPopupStatus]);

  const redirectMethod = (values, data) => {
    console.log("redirecttttttttttttttttttttttttttt from health quote --->>")
    formContext.setgetquotesApiFlag(true);
    setquotesResultsArray([]);
    try {
      const JsonDataForCreateQuotes = JSON.parse(data);

      console.log("Parsed Quote Data:", JsonDataForCreateQuotes);
      const quoteId = Array.isArray(JsonDataForCreateQuotes)
        ? JsonDataForCreateQuotes[0]?.QUOTE_ID
        : JsonDataForCreateQuotes?.QUOTE_ID;

      console.log("Quote ID:", quoteId);

      if (quoteId) {
        sessionStorage.setItem("quoteId", quoteId);
      } else {
        console.error("Quote ID is undefined");
      }

      formContext.setPolicyDates(JsonDataForCreateQuotes);
      console.log("Quotes create:", JsonDataForCreateQuotes.data);
      formContext.setcreatequotesresult(JsonDataForCreateQuotes.data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  
  const giveRating = (rating) => {
    return (<div className="">
      {(rating >= 1)
        ? <FullStar />
        : (rating >= 0.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 2)
        ? <FullStar />
        : (rating >= 1.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 3)
        ? <FullStar />
        : (rating >= 2.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 4)
        ? <FullStar />
        : (rating >= 3.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 5)
        ? <FullStar />
        : (rating >= 4.5)
          ? <HalfStar />
          : <ZeroStar />
      }
    </div>
    )
  }

  console.log("quotesResultsArray final", quotesResultsArray)

  useEffect(() => {
    if (formContext.selectedRiders) {
      setSelectedRiders(formContext.selectedRiders);
    }
  }, [formContext.selectedRiders]);

 


  const buyNow = (data) => {
    console.log("print data--->",data)
    if (data?.insuranceCompany === "Care Health Insurance"){
   console.log("insuranceCompany",data?.insuranceCompany)
   localStorage.setItem("uniqueId",data?.QUOTE_ID)
    }
      
        console.log("data",data,formik.values)
        const userLogin = sessionStorage.getItem("userLoggin");
        formContext.setsingleQuotesData(data);
        formContext.setSelectedHealthQuoteData(data);
                          formContext.setHealthQuoteFormikData(formik?.values);
                          formContext.setSelectedRiders([...defaultRiders, ...selectedRiders]);
                          // history.push("/healthProposal");
                          formContext.setloaderStatus(false);
        if (userLogin) {
          history.push("/healthProposal");
          formContext.setHealthQuoteFormikData(formik?.values);
          formContext.setloaderStatus(false);
        } else {
          formContext.setPages("Quotes");
          formContext.setloginPopupStatus(true);
        }
      };

  return (
    <>
      <style>
        {`
          [type="radio"]:checked,
          [type="radio"]:not(:checked)
          {
            opacity: unset;
            display: unset;
          }
          svg
          {
             display: unset;
          }
        `}
      </style>

      <section class="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
        <div class="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
          <div class="flex flex-wrap w-full">
            <div class="w-full px-[12px]">
              <div class="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <h2 class="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                    Health Quotes
                  </h2>
                </div>
                <div class="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <ul class="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                    <li class="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                      <a
                        href="index.html"
                        class="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li class="text-[14px] font-normal px-[5px]">
                      <i class="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                    </li>
                    <li class="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                      Health Quotes
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container className="get-quotes-wrap  justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px] pb-[0px] max-[1199px]:py-[35px]">
        <Row className='p-3 first-row' style={{ boxShadow: 'none' }}>
          <Col sm={12} md={12}>
            <Form className='form-ui' style={{ maxWidth: '100%' }}>
              <Row className="d-flex inner-form-box">
                <Col sm={12} md={12} style={{ textAlign: 'left' }}>
                  <div className='d-flex align-items-center'>
                    <b>Policy Details</b>
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => setMemberDetailsOpen(!memberDetailsOpen)}
                      style={{ fontSize: '14px', marginLeft: '10px', color: '#ec2d82', verticalAlign: 'bottom' }}
                    >
                      {memberDetailsOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </Button>
                  </div>
                </Col>

                <Collapse style={{ visibility: 'unset' }} className='row' in={memberDetailsOpen}>
                  <div>
                    <Col sm={12} md={3}>
                      <InputFieldDropdown
                        formik={formik}
                        formikFieldName="sumInsured"
                        label="Sum Insured"
                        optionsArray={sumInsuredValues}
                        value={formik.values.sumInsured} />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldDropdown
                        formik={formik}
                        formikFieldName="policyTerm"
                        label="Policy Term"
                        optionsArray={policyTermValues}
                        value={formik.values.policyTerm}
                      />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldDropdown formikFieldName="gender" label="Gender" formik={formik} optionsArray={genderValues} />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldText formik={formik} formikFieldName="email" label="Email" placeholder="Email" />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldText formik={formik} formikFieldName="phoneNumber" label="Phone no" placeholder="Phone no" />
                    </Col>
                    <Col sm={12} md={3}>
                      <InputFieldText formik={formik} formikFieldName="pincode" label="Pincode" placeholder="Pincode" />
                    </Col>
                  </div>
                </Collapse>
              </Row>
              <br></br>


              <Row className="inner-form-box">
                <Col sm={12} md={12} style={{ textAlign: 'left' }}>
                  <div className='d-flex align-items-center'>
                    <b>Insurance For</b>
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => setInsuranceForOpen(!insuranceForOpen)}
                      style={{ fontSize: '14px', marginLeft: '10px', color: '#ec2d82' }}
                    >
                      {insuranceForOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </Button>
                  </div>
                </Col>


                <Collapse in={insuranceForOpen} style={{ visibility: 'unset' }} className='row'>
                  <div>
                    {formik.values.healthInsurefor.map((member) => (
                      <React.Fragment key={member.id}>
                        <Col sm={6} md={1} className="d-flex justify-content-left align-items-center">
                          <div>{member.relation}</div>
                        </Col>

                        <Col sm={12} md={3}>
                          <FloatingLabel
                            controlId={`dateOfBirth-${member.id}`}
                            label="Date of birth"
                            aria-autocomplete="off"
                          >
                            <Form.Control
                              name={`dateOfBirth-${member.id}`}
                              type="date"
                              placeholder=""
                              className="floating-input"
                              value={member.dateOfBirth}
                              max={new Date()?.toISOString()?.slice(0, 10)}
                              onChange={(event) => changeDOB(event, member.id)}
                            />
                          </FloatingLabel>
                        </Col>
                      </React.Fragment>
                    ))}
                  </div>
                </Collapse>

                <Col md={12} sm={12} className="mt-3">
                  <div className="health-input">
                    {formik.values.existanceIllness === "Yes" ? (
                      <>
                        <p className="text-left-align"><b>Medical History</b></p>
                        <Row className="custom-checkbox align-items-center">
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik}
                              style={{ width: '15px', height: '15px', marginLeft: '6px' }}
                              formikFieldName="illnesses.diabetes"
                              className="cust-checkbox"
                              label="Diabetes"
                              checkedValue={formik.values.illnesses.diabetes} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.bloodPressure" label="Blood Pressure" checkedValue={formik.values.illnesses.bloodPressure} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.anySurgery" label="Any Surgery" checkedValue={formik.values.illnesses.anySurgery} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.thyroid" label="Thyroid" checkedValue={formik.values.illnesses.thyroid} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.asthma" label="Asthma" checkedValue={formik.values.illnesses.asthma} />
                          </Col>
                          <Col lg={2}>
                            <InputFieldCheckBox formik={formik} formikFieldName="illnesses.otherDiseases" label="Other disease" checkedValue={formik.values.illnesses.otherDiseases} />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <p className="text-left-align mb-0"><b>Existing illness</b></p>
                        <InputFieldText formik={formik} formikFieldName="existanceIllness" label="existanceIllness" />
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              {isFormDirty && (
                <div className="text-center mt-4 footer-btn-wrap">
                  <Button className="back-btn" onClick={handleCancel}>Cancel</Button>
                  <Button className="primary-btn" onClick={handleSave}>Save</Button>
                </div>
              )}
            </Form>
          </Col>
        </Row>

        <Row className="">
          <Col lg={12}>
            <div className="second-row d-flex align-items-center flex-wrap justify-content-between bg-color">
              <div className="d-flex gap-md-0 gap-sm-3 gap-2 align-items-center flex-wrap">
                <div>
                </div>
                <div>
                  <Button
                    onClick={() => setriderStatus(true)}
                    className="select-btn"
                  >
                    Additional Covers
                    <SVG src={Arrow} alt="" className="down-arrow" />
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      {/* {new working } */}
      <VerticallyCenteredModal
      show={riderStatus}
      onHide={() => setriderStatus(!riderStatus)}
      heading="Select Additional Covers"
    >
      {quotesResultsArray[0]?.riderList
        .filter((rider) => {
          const eldestMember = quotesResultsArray[0]?.healthInsurefor.reduce((max, current) => {
            return (current.age > max.age) ? current : max;
          });
          console.log("eldest member age",eldestMember?.age)
          const minAge = eldestMember?.age || 0;
          const isCoPaymentRider = rider.name.toLowerCase().includes("co-payment");

          if (isCoPaymentRider && minAge < 61) {
            return false;
          }

          if (rider.name === "NCB Super") {
            const zone = quotesResultsArray[0]?.zone || "";          
            if ((zone === "zone 1" || zone === "zone 1a") && minAge <= 45) {
              rider.isCheckedAutomatically = true;
              return true;
            }
          
            if (minAge >= 76 && minAge <= 99) {
              rider.isCheckedAutomatically = true;
              return true;
            }
          }

          return true;
        })
        .reduce((rows, rider, index) => {
          if (index % 2 === 0) rows.push([]);
          rows[rows.length - 1].push(rider);
          return rows;
        }, [])
        .map((ridersRow, rowIndex) => (
          <tr key={rowIndex} className="rider-row">
            {ridersRow.map((rider, colIndex) => {
              const isDefaultRider = defaultRiders.some(
                (defaultRider) => defaultRider.name === rider.name
              );
              const isSelected = selectedRiders.some(
                (selectedRider) => selectedRider.riderId === rider.riderId
              );
              const isAutoChecked = rider.name === "Global Coverage" && 
                ['200', '300', '600'].includes(formik.values.sumInsured);

              return (
                <td key={colIndex} className="rider-cell">
                  <div className="d-flex align-items-center">
                    <div>
                      <input
                        type="checkbox"
                        checked={isDefaultRider || isSelected || isAutoChecked || rider.isCheckedAutomatically}
                        disabled={isDefaultRider || isAutoChecked}
                        onChange={(e) => handleCheckboxChange(rider, e)}
                      />
                    </div>
                    <div>
                      <div className="text-left ml-2">
                        <strong>{rider.name}</strong>
                        <p style={{ fontSize: "12px", color: "#777", margin: "5px 0" }}>
                          {rider.description}
                        </p>

             
                        {rider.name === "Global Coverage" && (isSelected || isAutoChecked) && (
                          <div>
                            <select
                              className="form-control small-dropdown"
                              value={selectedGlobalCoverageOptions || "Not Opted"}
                              onChange={(e) => handleDropdownChange(rider, e)}
                              style={{
                                width: "108px",
                                height: "31px",
                                color: "grey",
                                fontSize: "12px",
                                marginTop: -"91px",
                              }}
                            >
                              {['25', '50', '100'].includes(formik.values.sumInsured) ? (
                                <>
                                  <option value="Not Opted">Not Opted</option>
                                  <option value="WW Excl. US and Canada">WW Excl. US and Canada</option>
                                  <option value="Worldwide">Worldwide</option>
                                </>
                              ) : (
                                <>
                                  <option value="WW Excl. US and Canada">WW Excl. US and Canada</option>
                                  <option value="Worldwide">Worldwide</option>
                                </>
                              )}
                            </select>
                          </div>
                        )}
 
                      </div>
                    </div>
                  </div>
                </td>
              );
            })}
            {ridersRow.length === 1 && <td className="rider-cell empty-cell"></td>} {/* Add empty cell if only one rider */}
          </tr>
        ))}
      <div className="modal-footer pr-0 border-0">
        <button
          className="primary-btn"
          onClick={() => {
            saveSelectedRiders();
            setriderStatus(false);
          }}
        >
          Save
        </button>
        <button
          className="back-btn mr-0"
          onClick={() => setriderStatus(false)}
        >
          Cancel
        </button>
      </div>
    </VerticallyCenteredModal>

          {/* popup for login screen */}
          <VerticallyCenteredModal
            show={formContext?.loginPopupStatus}
            onHide={() => {
              setownedByPopup(formContext.setloginPopupStatus(false));
            }}
            heading="Login with mobile number"
          >
            <SignIn />
          </VerticallyCenteredModal>

        <Row className="card-wrap">
          <Col lg={12}>
            {quotesResultsArray && quotesResultsArray.length > 0 && quotesResultsArray?.filter((filterData) => (Number(filterData?.netPremium)) !== 0)?.map((data, index) => {
              const rating = (((data?.insurerIndex) + (data?.claimIndex) + 4.0) / 3)?.toFixed(1);
              return (
                <Card key={index}>
                  <Card.Body>
                    <div className="card-top card-header">
                      <div className="logo-wrap">
                        <img
                          // src={imgArrayForCard?.[data?.insuranceCompany]}
                          src={careLogo}
                          alt=""
                          className="logo"
                        />
                      </div>

                      <div className="premium">
                        {/* <p className="label-text">Plan Type</p> */}
                        <p className="premium-text">{data?.planName}</p>
                      </div>

                    </div>

                    <div className="offer-wrap">
                    </div>
                    <div className="card-middle card-details">
                      <div className="card-rating-wrap">
                        <div className="card_rating mb-0">
                          <span className="label-text">Rating</span>
                          <span className="label-text badge">{rating}</span>
                        </div>
                        <div className="star-icon-wrap">
                          {giveRating(rating)}
                        </div>
                      </div>

                      <div className="premium">
                        <p className="label-text">Premium</p>
                        {/* <p className="premium-text">₹ {new Intl.NumberFormat('en-IN').format(Math.round(data?.basePremium))}</p> */}
                        {/* <p className="premium-text">₹ {new Intl.NumberFormat('en-IN').format(Math.round(data?.basePremium))}</p>  */}
                        {/* Display the recalculated premium */}
                        <p className="premium-text">
                          {/* ₹ {new Intl.NumberFormat('en-IN').format(Math.round(totalPremium || data?.basePremium))} */}
                          ₹ {new Intl.NumberFormat('en-IN').format(Math.round(data?.newPermium || quotesResultsArray[0]?.totalPremium.replace(/,/g, "")))}
                        </p>
                      </div>
                    </div>
                    <div className="card-bottom rating d-flex gap-4 justify-content-center">
                      <div className="premium">
                        <div className="star rating-star">
                          <SVG
                            src={Star}
                            alt=""
                            className="rating-star-icon"
                            fill="#d4d4d4"
                          />
                          <span className="rating-point">4.0</span>
                        </div>
                        <p className="premium-text">Premium</p>
                      </div>
                      <div className="premium">
                        <div className="star rating-star">
                          <SVG
                            src={Star}
                            alt=""
                            className="rating-star-icon"
                            fill="#d4d4d4"
                          />
                          <span className="rating-point">{(data?.insurerIndex)?.toFixed(1)}</span>
                        </div>
                        <p className="premium-text">Servicing</p>
                      </div>

                      <div className="premium">
                        <div className="star rating-star">
                          <SVG
                            src={Star}
                            alt=""
                            className="rating-star-icon"
                            fill="#d4d4d4"
                          />
                          <span className="rating-point">{(data?.claimIndex)?.toFixed(1)}</span>
                        </div>
                        <p className="premium-text">Claims</p>
                      </div>
                    </div>


                    <div className="card-top card-header" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button
                        variant="primary"
                        className="buy-btn"
                        onClick={() => {
                          buyNow(data);
                        }}

                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Buy Now
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
        <button
          title="Back"
          onClick={() => backButtonPressed()}
          className="back-button"
        >
          {" "}
          <SVG src={Arrow} alt="" className="back-arrow" />
        </button>
      </Container>
    </>
  );
};

export default HealthQuote;