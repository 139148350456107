import React, { useEffect, useState } from "react";

export const CarePayment = () => {
  const queryParams = new URLSearchParams(window.location.search);
  console.log("queryParams", queryParams.get("parameters"));
  const proposalId = localStorage?.getItem("proposalId")

  const valuesForPayload = {
    proposalId: queryParams.get("proposalNum"),
    returnURL: queryParams.get("returnURL") + "/"+proposalId,
    // returnURL:"",
    // paymentURL: "https://apiuat.careinsurance.com/portalui/PortalPayment.run"
    paymentURL: "https://api.careinsurance.com/portalui/PortalPayment.run"
  };

  useEffect(() => {
    console.log("valuesForPayload", valuesForPayload);
    if (valuesForPayload.proposalId) {
      const submitButton = document.getElementById("submitButton");
      submitButton?.click();
    }
  }, [valuesForPayload.proposalId]);

  return (
    <div hidden={true}>
      <h2>Health Insurance Payment</h2>
      <form
        action={valuesForPayload.paymentURL}
        name="PAYMENTFORM"
        id="PAYMENTFORM"
        method="post"
      >
        <input
          type="hidden"
          name="proposalNum"
          value={valuesForPayload.proposalId}
        />
        <input
          type="hidden"
          name="returnURL"
          value={valuesForPayload.returnURL}
        />
        <button type="submit" id="submitButton">
          Submit Payment
        </button>
      </form>
    </div>
  );
};